import React from 'react';

const FormatDate = (props) => {
    const {date} = props;

    const yyyy = date.slice(0, 4);
    const mm = parseInt(date.slice(5, 7));
    const dd = date.slice(8, 10);

    const month = [ "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
        "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre" ];

    return (
        <div>
            {dd} {month[mm-1]} {yyyy}
        </div>
    );
};

export default FormatDate;