import React, {useContext, useState, useEffect, useCallback} from 'react';
import withAuth from "../../hoc/withAuth";
import {AppContext} from "../../../context/AppContext";
import {Button, Search, Table} from "../../index";
import styles from './Admin.module.css';

import {useGetInsertCoin} from "../../../hooks";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";
import {MdMicExternalOn} from "react-icons/md";
import {IoMic, IoMusicalNotesSharp, IoVideocam} from "react-icons/io5";
import {IoIosArrowBack, IoIosArrowForward, IoMdBrush} from "react-icons/io";
import {TbEyeCheck} from "react-icons/tb";

const GetInsertCoin = () => {
    const appData = useContext(AppContext);
    const [page, setPage] = useState(1);
    const insertedCoinList = useGetInsertCoin(appData.user.data.email, page, []);
    const nextPageList = useGetInsertCoin(appData.user.data.email, (page + 1), []);
    const [data, setData] = useState(insertedCoinList !== null ? insertedCoinList : null)
    const [nextPageData, setNextPageData] = useState(nextPageList !== null ? nextPageList : null)
    const [backup, setBackup] = useState(insertedCoinList !== null ? insertedCoinList : null)
    const [hide, setHide] = useState(true);
    const [rapper, setRapper] = useState(false);
    const [singer, setSinger] = useState(false);
    const [producer, setProducer] = useState(false);
    const [graphic, setGraphic] = useState(false);
    const [videomaker, setVideomaker] = useState(false);
    const [seen, setSeen] = useState(false)


    useEffect(() => {
        if (insertedCoinList !== null) {
            setData(insertedCoinList)
            setBackup(insertedCoinList)
        }
        if (nextPageList !== null) {
            setNextPageData(nextPageList)
        }
    }, [insertedCoinList, nextPageList])

    return (
        <>
            <div className={styles.container}>

                <div className={styles.navbar}>
                    <h3 className={styles.text}> Ciao <span
                        style={{color: 'var(--green)'}}>{appData.user.data.name}</span>!</h3>
                    <div className={styles.buttons}>

                        <Button buttonStyle={"circle"} children={!hide ? <AiFillEye/> : <AiFillEyeInvisible/>}
                                onClick={() => {
                                    setHide(!hide)
                                }}/>
                        <Button buttonStyle={"circle"}
                                children={!rapper ? <MdMicExternalOn/> : <MdMicExternalOn fill={appData.color.green}/>}
                                onClick={() => {
                                    setRapper(!rapper)
                                }}/>
                        <Button buttonStyle={"circle"} children={!producer ? <IoMusicalNotesSharp/> :
                            <IoMusicalNotesSharp fill={appData.color.green}/>} onClick={() => {
                            setProducer(!producer)
                        }}/>
                        <Button buttonStyle={"circle"} children={!singer ? <IoMic/> :
                            <IoMic fill={appData.color.green} color={appData.color.green}/>} onClick={() => {
                            setSinger(!singer)
                        }}/>
                        <Button buttonStyle={"circle"}
                                children={!graphic ? <IoMdBrush/> : <IoMdBrush fill={appData.color.green}/>}
                                onClick={() => {
                                    setGraphic(!graphic)
                                }}/>
                        <Button buttonStyle={"circle"}
                                children={!videomaker ? <IoVideocam/> : <IoVideocam fill={appData.color.green}/>}
                                onClick={() => {
                                    setVideomaker(!videomaker)
                                }}/>
                        <Button buttonStyle={"circle"}
                                children={!seen ? <TbEyeCheck/> : <TbEyeCheck color={appData.color.green}/>}
                                onClick={() => {
                                    setSeen(!seen)
                                }}/>
                        <Search list={data} backupList={backup} onChangeList={setData}/>

                    </div>
                </div>


                <Table data={data} titles={appData.titlesTable} popup={"insert-coin"} rapper={rapper}
                       producer={producer} singer={singer} graphic={graphic} videomaker={videomaker} seen={seen}
                       hide={hide} page={page}/>
                {data !== null ? <div className={styles.pages}>
                    {page !== 1 ? <IoIosArrowBack onClick={() => setPage(page - 1)} className={styles.arrow}/> :
                        <div className={styles.arrow}/>}
                    {page}
                    {nextPageData !== null ?
                        <IoIosArrowForward onClick={() => setPage(page + 1)} className={styles.arrow}/> :
                        <div className={styles.arrow}/>}

                </div> : null}
            </div>


        </>
    );
};

export default withAuth(GetInsertCoin);