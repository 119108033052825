import React, {useState} from 'react';
import styles from './Roster.module.css'
import {BsPlusLg, BsDashLg} from "react-icons/bs";
import {GrArticle} from "react-icons/gr";
import Awards from "./Awards";

const Articles = (props) => {
    const {artist, color, open, onChangeOpen} = props;


    const sizeIconTitle = 30
    const sizeIconArticle = 30

    return (
        <>
            <div className={styles.subsection}>
                <div className={styles.title}>
                    <h2>LINK</h2>
                    <div className={styles.icon} onClick={() => {
                        onChangeOpen(!open)
                    }}>{!open ? <BsPlusLg size={sizeIconTitle}/> : <BsDashLg size={sizeIconTitle}/>}</div>
                </div>

                {open ? artist.articles_name.map((x, i) => {
                    return (
                        <h3 key={i} className={styles.links}>
                            <a href={artist.articles_link[i]} target={"_blank"}>
                            <GrArticle className={styles.icon} fill={color.green} size={sizeIconArticle} />
                                {x}
                            </a>
                        </h3>
                    )
                }) : null}
            </div>

        </>
    );
};

export default Articles;