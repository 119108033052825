import React, {useEffect, useRef, useState} from 'react';
import styles from './Feed.module.css'
import axios from "axios";
import {Post} from "../../../index";
import {BallTriangle} from "react-loader-spinner";

const Instagram = (props) => {
    const {token, limit, onChangeShow} = props
    const [posts, setPostsData] = useState([])
    //use useRef to store the latest value of the prop without firing the effect
    const tokenProp = useRef(token);
    const maxLenPosts = 4;
    tokenProp.current = token;


    useEffect(() => {
        // this is to avoid memory leaks
        const abortController = new AbortController();

        async function fetchInstagramPost() {
            try {
                const resp = await axios.get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,timestamp&limit=${limit}&access_token=${token}`);

                setPostsData(resp.data.data);
            } catch (err) {
                onChangeShow(false);
            }
        }

        // manually call the fecth function
        fetchInstagramPost();

        return () => {
            // cancel pending fetch request on component unmount
            abortController.abort();
        };
    }, [limit])

    let news = []
    let mainPost;
    posts.map((post, i) => {
        if (post.media_type !== "VIDEO" && news.length < maxLenPosts) {
            news.push(post)
        }
    })
    let instagramPost;


    if ( news.length === maxLenPosts){
        instagramPost = (
            <>

                {/*<div className={styles.mainPost}>
                    <Post post={news[0]} isMainPost={true}/>
                </div>*/}
                <div className={styles.grid}>
                    {
                        news.map((post, i) => {
                      /*      if (i > 0)*/
                                return (
                                    <>
                                        <Post key={post.id} post={post} isMainPost={false}/>
                                    </>
                                )
                        })
                    }
                </div>
            </>
        );
    } else {
        instagramPost = <BallTriangle
            height={50}
            width={50}
            radius={5}
            color="#4fa94d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
        />;
    }

    return(
        <>
            {instagramPost}
        </>
    );

}

export default Instagram;