import React from 'react';
import styles from './ScrollableTimeline.module.css';

const ScrollableTimeline = (props) => {

    const {time, year, onChangeYear} = props;

    return (
        <>
            <div className={styles.container}>
                {time.map((x, i) => {
                    return <h2 className={year !== x ? styles.item : styles.itemActive} onClick={() => onChangeYear(x)}>{x}</h2>
                })}
            </div>

        </>
    );
};

export default ScrollableTimeline;