import React, {useState} from 'react';
import styles from './Feed.module.css'
import {CenterTitle} from "../../../index";
import Button from "../../../Button/Button";
import Instagram from "./Instagram";


const Feed = () => {
    const [show, setShow] =  useState(true);
    return (
        <>
            <section>
                {show ? <><CenterTitle children={"NEWS"}/>
                    <div className={styles.container}>
                        <Instagram
                            token={'IGAAIelL1H9RVBZAE14RUFKaUl6TU9jbFp4YWd6bjdnZADJpczFjM20wR0RrVmdJRDQ3cHZAmb2lnRE9ldndId3FFV3Jrano0N3hoeFU2NU5HX1hvVU5xQ2VaQzlDVVpweGU0alpCdWFLYy1ONzl1TjhHbDNGRURObHJ1M2hFZA0c1QQZDZD'}
                            limit={10}
                            onChangeShow={setShow}
                        />


                        <a href={"https://www.instagram.com/aarmusiclabel/"} target={"_blank"}>
                            <Button buttonStyle={"black2"}>
                                Altre News
                            </Button>
                        </a>
                    </div></> : null}

            </section>

        </>
    );
};

export default Feed;