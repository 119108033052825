import {useEffect, useState} from 'react';
import {ApiGetGenre, ApiGetMood, ApiGetSkill} from "../api";

const useGetSkill = (id) => {
    const [state, setState] = useState({
        "user_id": id,
        "tecnica": false,
        "timbro": false,
        "flow": false,
        "testo": false
    });

    useEffect(() => {
        setSkill(id);

    }, []);

    const setSkill = async (id) => {
        const skillInfo = await ApiGetSkill(id);
        console.log("Area info", skillInfo);
        if(skillInfo !== null){
            setState(skillInfo.data);
        } else {
            setState({
                "user_id": id,
                "tecnica": false,
                "timbro": false,
                "flow": false,
                "testo": false
            })
        }

    };
    return [state, setState];
};

export default useGetSkill;
