import {useEffect, useState} from 'react';
import {ApiGetArea} from "../api";

const useGetArea = (id) => {
    const [state, setState] = useState({
        "user_id": id,
        "rapper": false,
        "producer": false,
        "singer": false,
        "graphic_designer": false,
        "videomaker": false
    });

    useEffect(() => {
        setArea(id);

    }, []);

    const setArea = async (id) => {
        const areaInfo = await ApiGetArea(id);
        if(areaInfo !== null){
            setState(areaInfo.data);
        } else {
            setState({
                "user_id": id,
                "rapper": false,
                "producer": false,
                "singer": false,
                "graphic_designer": false,
                "videomaker": false
            })
        }

    };
    return [state, setState];
};

export default useGetArea;
