import React, {useEffect, useState} from 'react';
import styles from './Carousel.module.css'
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";

const ImageSlider = (props) => {
    const {artist} = props
    const [current, setCurrent] = useState(1);
    const [importImg, setImportImg] = useState(artist.img.length > 1 ? require('../../data/img/artists/' + artist.img[current]) : null);
    useEffect(() => {
        if (artist.img.length > 1) {
            setImportImg(require('../../data/img/artists/' + artist.img[current]));
        }
    }, [current, artist])


    const nextSlide = () => {
        setCurrent(current === artist.img.length - 1 ? 0 : current + 1)
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? artist.img.length - 1 : current - 1);
    };


    return (
        <>
            <div className={styles.slider}>

                <div className={styles.bgimage}>

                    <div className={styles.arrows} onClick={prevSlide}><IoIosArrowBack/></div>
                    <img src={importImg} alt={artist.img[current]} className={styles.image}/>
                    <div className={styles.arrows} onClick={nextSlide}><IoIosArrowForward/></div>
                </div>
            </div>
        </>
    );
};

export default ImageSlider;