import React, {useContext, useState} from 'react';
import styles from "../Roster/Roster.module.css";
import {Link} from "react-router-dom";
import {OverlayCard} from "../../index";
import {AppContext} from "../../../context/AppContext";

const Team = () => {
    const appData = useContext(AppContext);
    const [data, setData] = useState(appData.rosterList.artists);
    return (
        <>
            <div className={styles.grid}>

                {
                    data.filter(x => x.staff).map((x, i) => {
                        return (
                            <>
                                <Link to={`/roster/${x.link}`}>
                                    <OverlayCard img={x.img[0]} text={x.name}/>
                                </Link>
                            </>
                        )
                    })}



            </div>
        </>
    );
};

export default Team;