import React from 'react';
import {Button} from "../index";
import {ApiIsBot} from "../../api";
import Popup from "./Popup";

const BotPopup = (props) => {
    const {isBot, onChangeOpenPopupBot, onChangeOpen} = props
    return (
        <>
            <Popup> Sei sicuro che vuoi confermare questo artista come bot? <Button onClick={() => {
                ApiIsBot(isBot);
                onChangeOpenPopupBot(false);
                onChangeOpen(false);
                window.location.reload();
            }}> Sì</Button> <Button onClick={() => onChangeOpenPopupBot(false)}>No</Button> </Popup>
        </>
    );
};

export default BotPopup;