import React from 'react';
import styles from './ToggleSwitch.module.css'

const ToggleSwitch = (props) => {
    const {checked, onChange, onClick} = props
    return (
        <>
            <div className={styles.toggleSwitch}>
                <input
                    type="checkbox"
                    className={styles.checkbox}
                    name="admin"
                    id="admin"
                    checked={checked}
                    onChange={onChange}
                />

                {console.log(checked)}
                <label className={styles.label} htmlFor="admin" onClick={onClick}>
                    <span className={styles.inner} />
                    <span className={styles.switch} />
                </label>
            </div>
        </>
    );
};

export default ToggleSwitch;