import React, {useContext, useEffect, useState} from 'react';
import {useGetUsers} from "../../../hooks";
import {Table} from "../../index";
import {AppContext} from "../../../context/AppContext";
import styles from "./Admin.module.css";

const GetUsers = () => {
    const appData = useContext(AppContext);
    const userList = useGetUsers();

    return (
        <>
            <div className={styles.container}>

            {userList != null ? <Table data={userList} titles={appData.userTitlesTable}/>
                : null}
            </div>
        </>
    );
};

export default GetUsers;