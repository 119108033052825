import {useEffect, useState} from 'react';
import {ApiGetMood} from "../api";

const useGetMood = (id) => {
    const [state, setState] = useState({
        "user_id": id,
        "relax": false,
        "energico": false,
        "buonumore": false,
        "meditazione": false,
        "romantico": false,
        "triste": false,
        "sexy": false
    });

    useEffect(() => {
        setMood(id);

    }, []);

    const setMood = async (id) => {
        const moodInfo = await ApiGetMood(id);
        if(moodInfo !== null){
            setState(moodInfo.data);
        } else {
            setState({
                "user_id": id,
                "relax": false,
                "energico": false,
                "buonumore": false,
                "meditazione": false,
                "romantico": false,
                "triste": false,
                "sexy": false
            })
        }

    };
    return [state, setState];
};

export default useGetMood;
