import React, {useEffect, useState} from 'react';
import styles from './Roster.module.css'
import {Banner} from "../../index";
import Carousel from "../../ElasticCarousel";
import useMediaQuery, {Device} from "../../../hooks/useMediaQuery";

const Video = (props) => {
    const {artist, colorBg, title} = props;
    const [showArrow, setShowArrow] = useState(false)

    const isMobile = useMediaQuery(Device.sm);

    const breakPoints = [
        {width: 1, itemsToShow: 1},
        {width: 600, itemsToShow: 2},
        {width: 900, itemsToShow: 3},
        {width: 1200, itemsToShow: 4},
    ];

    useEffect(() => {
        if(isMobile) {
            setShowArrow(false);
        } else { setShowArrow(true) }
    }, [isMobile])

    return (
        <>
            <Banner colorBg={colorBg} right={true}>
                <div className={styles.padding}>
                    {title !== null ? <h2 className={styles.title}>{title}</h2> : null }
                    <div className={styles.carousel}>

                        <Carousel showArrows={showArrow} breakPoints={breakPoints}>
                            {artist.video.map((link, i) => {
                                return (<iframe key={i} src={`${link}`} className={styles.iframe}
                                                title="YouTube video player"
                                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>)
                            })}
                        </Carousel>
                    </div>
                </div>
            </Banner>
        </>
    );
};

export default Video;