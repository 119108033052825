import React, {useContext, useEffect, useState} from 'react';
import styles from './Table.module.css';
import RowDetails from "./RowDetails";
import RowTitle from "./RowTitle";
import {InsertCoinPopup, ServiceRequestsPopup} from "../index";
import useDemoFiltered from "../../hooks/useDemoFiltered";
import {AppContext} from "../../context/AppContext";

const Table = (props) => {
    const appData = useContext(AppContext);
    const {data, titles, rapper, singer, producer, graphic, videomaker, seen, hide, popup, page} = props;
    const [open, setOpen] = useState(false);
    const [demo, setDemo] = useState(null);
    const filtered = useDemoFiltered(appData.user.data.email, data, rapper, producer,singer , graphic, videomaker, seen, page);
    const [dataFiltered, setDataFiltered] = useState(filtered !== null ? filtered : data)

    useEffect(() => {
        if (filtered !== null){
            setDataFiltered(filtered)
        } else {
            setDataFiltered(data)
        }
    },[filtered, data])

    return (
        <>
            <div className={styles.container}>

                <ul className={styles.table}>
                    <RowTitle titles={titles}/>

                    {dataFiltered !== null ? <RowDetails row={dataFiltered} titles={titles} open={open} onChangeOpen={setOpen} demo={demo}
                                                         onChangeDemo={setDemo} hide={hide}/> : <div>LOADING ... </div>}

                    {
                        open && popup==="insert-coin" ? <InsertCoinPopup open={open} onChangeOpen={setOpen} demo={demo} hide={hide} /> : null
                    }

                    {
                        open && popup==="service-requests" ? <ServiceRequestsPopup open={open} onChangeOpen={setOpen} demo={demo} hide={hide} /> : null
                    }
                </ul>

            </div>
        </>
    );
};

export default Table;