import React, {useContext, useEffect, useRef, useState} from 'react';
import {Answer, CenterTitle, Question} from "../../components";
import { gsap } from 'gsap';
import styles from './Faq.module.css';
import {AppContext} from "../../context/AppContext";


const Faq = () => {
    const appData = useContext(AppContext);
    const [openId, setOpenId] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const answerRef = useRef(null)

    useEffect(() => {
        gsap.to(answerRef.current, {
            duration: 1,
            display: 'none',
            ease: 'none'
        })
    }, [openId])
    useEffect(() => {
        gsap.from(answerRef.current, {
            duration: 1,
            display: 'visible',
            ease: 'none'
        })
    }, [openId])

    return (
        <>
            <section>

                <CenterTitle children={"FAQ"}/>
                <div className={styles.page}>
                    {
                        appData.faqList.map((x, i) => {
                            return (
                                <>


                                    <Question id={x.id} openId={openId} onChangeOpenId={setOpenId}>
                                        {x.question}
                                    </Question>
                                    <div ref={answerRef}>
                                        {openId === i ? <Answer>
                                                {x.answer}
                                            </Answer>
                                            : null
                                        }
                                    </div>
                                </>
                            )
                        })
                    }
                </div>


            </section>
        </>
    );
};

export default Faq;