import React from 'react';
import styles from './Table.module.css'

const RowTitle = (props) => {
    const {titles} = props;

    const colSize = (title) => {
        switch (title.size){
            case "xsmall":
                return <div key={title.name} className={styles.xsmallCol}>{title.name}</div>
            case "small":
                return <div key={title.name} className={styles.smallCol}>{title.name}</div>
            case "medium":
                return <div key={title.name} className={styles.mediumCol}>{title.name}</div>
            case "large":
                return <div key={title.name} className={styles.largeCol}>{title.name}</div>
        }
    }

    return (
        <>

            <li className={styles.tableHeader}>
                {
                    titles.map((title, i) => {

                        return colSize(title)
                    })
                }
            </li>
        </>
    );
};

export default RowTitle;