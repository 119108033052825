import React from 'react';
import styles from './SideTitle.module.css'
import {Device, useMediaQuery} from "../../hooks";
import ResizeText from "../Text/ResizeText";

const SideTitle = (props) => {
    const {children, linkChildren, bgColor=null, color=null} = props;
    const isMobile = useMediaQuery(Device.sm)
    return (
        <>
            <div className={styles.container} style={{background: `${bgColor}`, color: `${color}`}}>
                {isMobile ? <h3>{children}</h3> : <ResizeText> {children} </ResizeText>}
                {linkChildren ? <a className={"linkSideTitle"} style={{margin: "1em 20px 5px 20px", color: "#626262"}} href={linkChildren} target="_blank" rel="noreferrer">
                    Technical Details
                </a> : null}
                
            </div>
        </>
    );
};

export default SideTitle;