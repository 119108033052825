import React from 'react';
import styles from './Faq.module.css'

const Answer = (props) => {
    const {children, id} = props;
    return (
        <>
            <div className={styles.answer}>
                {children}
            </div>
        </>
    );
};

export default Answer;