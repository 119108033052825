import React from 'react';
import {CenterTitle, Form} from "../../components";

const Register = () => {
    return (
        <>
            <section>
                <CenterTitle children={"Register"}/>
                <Form type={"register"}/>
            </section>
        </>
    );
};

export default Register;