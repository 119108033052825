import React, {useMemo, useState} from "react";

/* FROM JSON */
import menuList from '../data/json/menu.json';
import footerMenuList from '../data/json/footerMenu.json';
import rosterList from '../data/json/roster.json';
import servicesList from '../data/json/services.json';
import faqList from '../data/json/faq.json';
import titlesTable from '../data/json/titlesTable.json';
import userTitlesTable from '../data/json/usersTitlesTable.json'
import serviceRequestsTitlesTable from '../data/json/serviceRequestsTitlesTable.json'

/* FROM JSON TO DB */
import areaList from '../data/json/areaList.json';
import genreList from '../data/json/genreList.json';
import moodList from '../data/json/moodList.json';
import skillList from '../data/json/skillList.json';

/* HOOKS */
import {useAuth, useGetInsertCoin} from "../hooks";

export const AppContext = React.createContext({});

export function useAppContext() {

    const [auth, user, login, validateSession] = useAuth();

    /* Link to css root colors */
    const green = getComputedStyle(document.documentElement).getPropertyValue('--green');
    const black = getComputedStyle(document.documentElement).getPropertyValue('--black');
    const grey = getComputedStyle(document.documentElement).getPropertyValue('--grey');
    const white = getComputedStyle(document.documentElement).getPropertyValue('--white');

    const [artist, setArtist] = useState(null);

    const sortByAwards = (arr, field) => {
        return arr.sort((a, b) => {
            if (a[field].length < b[field].length) { return 1; }
            if (b[field].length < a[field].length) { return -1; }
            return 0;
        })
    }

    const sort = (arr, field) => {
        return arr.sort((a, b) => {
            if (a[field] < b[field]) { return 1; }
            if (b[field] > a[field]) { return -1; }
            return 0;
        })
    }

    return useMemo(
        () => ({
            menuList,
            footerMenuList,
            rosterList,
            servicesList,
            faqList,
            titlesTable,
            userTitlesTable,
            serviceRequestsTitlesTable,
            areaList,
            moodList,
            genreList,
            skillList,

            user: {
                auth,
                data: user,
                login,
                validateSession
            },

            artist:{
                data: artist,
                setData: setArtist
            },

            color:{
                green: green,
                black: black,
                grey: grey,
                white: white
            },

            function:{
                sortByAwards: sortByAwards,
                sort: sort
            }
        }),
        [ artist, user, auth, login]
    );
}