import React, {useCallback, useContext, useEffect, useState} from 'react';
import validate from './ValidateInfo';
import styles from '../Form.module.css'
import {Button, InputLeft, ToggleSwitch} from "../../index";


import {RiLockPasswordLine} from "react-icons/ri";
import {BiAt, BiUserCircle} from "react-icons/bi";
import useFormRegister from "./UseFormRegister";
import withAuth from "../../hoc/withAuth";
import {AppContext} from "../../../context/AppContext";
import {useRegister} from "../../../hooks";
import FormResponse from "../FormResponse";
import {BallTriangle} from "react-loader-spinner";
import {Link} from "react-router-dom";

const FormRegister = (props) => {
    const appData = useContext(AppContext)
    const [register, sending, result] = useRegister(appData.user.data.email);
    const {handleChange, handleChangeChecked,  submit, values, errors, loading} = useFormRegister(
        register,
        validate,
    );


    if (result === "OK") return (
        <FormResponse>
            <h3>Complimenti! Nuovo utente registrato.</h3>
            <br/>
            <center><Link to={"/"}><Button>Home</Button></Link></center>
        </FormResponse>
    );
    if (result === "NOT_ADMIN") return (
        <FormResponse>
            <h3>Mi dispiace, non sei un admin. Contattare l'admin per registrare un nuovo utente</h3>
            <br/>
            <center><Link to={"/"}><Button>Home</Button></Link></center>
        </FormResponse>
    )
    if (result === "FAILED") return (
        <FormResponse>
            <h3 style={{color: 'var(--red)'}}>Mi dispiace, si è verificato un errore.</h3>
            <br/>
            <center><Link to={"/"}><Button>Home</Button></Link></center>
        </FormResponse>
    );

    return (
        <>
            <div className={styles.text}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
            </div>
            <form onSubmit={submit} className={styles.form} noValidate>
                <div className={styles.container}>


                    {/*NAME*/}

                    <div className={styles.inputs}>
                        <InputLeft input={"input"} icon={<BiUserCircle size={20}/>} type={"text"} name={"name"}
                                   placeholder={"Enter your name"} value={values.name} onChange={handleChange}/>

                        {errors.name && <p>{errors.name}</p>}
                    </div>


                    {/*SURNAME*/}
                    <div className={styles.inputs}>
                        <InputLeft input={"input"} icon={<BiUserCircle size={20}/>} type={"text"} name={"surname"}
                                   placeholder={"Enter your surname"} value={values.surname}
                                   onChange={handleChange}/>

                        {errors.surname && <p>{errors.surname}</p>}
                    </div>

                    <div className={styles.inputs}>
                        <InputLeft input={"input"} icon={<BiUserCircle size={20}/>} type={"text"} name={"username"}
                                   placeholder={"Enter your username"} value={values.username}
                                   onChange={handleChange}/>

                        {errors.surname && <p>{errors.surname}</p>}
                    </div>

                    {/*EMAIL*/}
                    <div className={styles.inputs}>

                        <InputLeft input={"input"} icon={<BiAt size={20}/>} type={"text"} name={"email"}
                                   placeholder={"Enter your email"} value={values.email} onChange={handleChange}/>

                        {errors.email && <p>{errors.email}</p>}
                    </div>


                    <div className={styles.inputs}>

                        <InputLeft input={"input"} icon={<RiLockPasswordLine size={20}/>} type={"password"}
                                   name={"password"}
                                   placeholder={"Enter your password"} value={values.password} onChange={handleChange}/>

                        {errors.password && <p>{errors.password}</p>}
                    </div>

                    <div className={styles.inputs}>

                        <InputLeft input={"input"} icon={<RiLockPasswordLine size={20}/>} type={"password"}
                                   name={"re_password"}
                                   placeholder={"Confirm your password"} value={values.re_password}
                                   onChange={handleChange}/>

                        {errors.re_password && <p>{errors.re_password}</p>}
                    </div>

                    <div className={styles.inputs}>
                        <div className={styles.admin}>
                            <ToggleSwitch checked={values.isAdmin} onChange={handleChangeChecked}/>
                            <div className={styles.help}>Admin</div>
                        </div>
                    </div>

                    {!loading ? <Button type='submit' disabled={sending}>
                        Register
                    </Button> : <BallTriangle
                        height={50}
                        width={50}
                        radius={5}
                        color="#4fa94d"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                    }
                </div>

            </form>
        </>
    );
};

export default withAuth(FormRegister);