import React from 'react';
import styles from './InsertCoin.module.css'
import {CenterTitle, Form, FormInsertCoin} from "../../components";

const InsertCoin = () => {
    return (
        <>
            <section>
                <CenterTitle children={"Insert coin"}/>
                <Form type={"insert-coin"}/>

            </section>

        </>
    );
};

export default InsertCoin;