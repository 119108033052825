import React, {useState, useContext} from 'react';
import styles from "./Navbar.module.css";
import useMediaQuery, {Device} from "../../hooks/useMediaQuery";
import {Link, useLocation} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import Icons from "../Icons/Icons";
import {FaSeedling} from "react-icons/fa";
import {RiBearSmileFill} from "react-icons/ri";

/***
 * This arrow function return menu component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

// TODO: INSERIRE L'IMMAGINE DEL GERMOGLIO SOTTO LE ICONE SE MOBILE

function Menu(props) {

    const {openMobile, onChangeOpenMobile, navbar} = props; // define if menu in mobile is open or not

    const appData = useContext(AppContext);
    const isMobile = useMediaQuery(Device.sm);
    const {pathname} = useLocation();

    const closeMobileMenu = (link) => {
        if (isMobile) {
            onChangeOpenMobile(!openMobile);
        }
    }

    return (
        <>

            <div style={isMobile ? (openMobile ? {transform: "translateX(0)"} : {transform: "translateX(100%)"}) : null}
                 className={styles.menu}>
                {
                    /* map function to see all element di menuList */
                    appData.menuList.map((x, i) => {
                        if(x.name === "insert coin" || x.name === "studio")
                        {
                            return <a href={`${x.link}`} >{isMobile ? <div
                                className={pathname === x.link ? styles.itemActive : styles.item}
                                onClick={() => {
                                    closeMobileMenu(x.link);
                                }}>{x.name}{x.name === "insert coin" ? <FaSeedling style={{marginLeft: "0.5rem"}}/> : x.name === "studio" ? <RiBearSmileFill style={{marginLeft: "0.5rem"}}/> : null}</div> : <div
                                className={pathname === x.link ? styles.itemActive : navbar ? styles.itemBlack : styles.item}
                                style={navbar && pathname === x.link ? {color: appData.color.white} : null}
                                onClick={() => {
                                    closeMobileMenu(x.link);
                                }}>{x.name}{x.name === "insert coin" ? <FaSeedling style={{marginLeft: "0.5rem"}}/> : x.name === "studio" ? <RiBearSmileFill style={{marginLeft: "0.5rem"}}/> : null }</div>}</a>
                        } else{
                            return (
                                <Link to={`${x.link}`} key={i}>
                                    {isMobile ? <div
                                        className={pathname === x.link ? styles.itemActive : styles.item}
                                        onClick={() => {
                                            closeMobileMenu(x.link);
                                        }}>{x.name}{x.link === "/insert-coin" ? <FaSeedling style={{marginLeft: "0.5rem"}}/> : x.link === "/studio" ? <RiBearSmileFill style={{marginLeft: "0.5rem"}}/> : null}</div> : <div
                                        className={pathname === x.link ? styles.itemActive : navbar ? styles.itemBlack : styles.item}
                                        style={navbar && pathname === x.link ? {color: appData.color.white} : null}
                                        onClick={() => {
                                            closeMobileMenu(x.link);
                                        }}>{x.name}{x.link === "/insert-coin" ? <FaSeedling style={{marginLeft: "0.5rem"}}/> : x.link === "/studio" ? <RiBearSmileFill style={{marginLeft: "0.5rem"}}/> : null }</div>}
                                </Link>)
                        }
                        
                    })}
                {isMobile ? <Icons/> : null}
            </div>
        </>
    );
}

export default Menu;