import { useCallback, useState } from "react";
import {ApiSendServiceRequest} from "../api";

const useSendServiceRequest = () => {
    const [sending, setSending] = useState(false);
    const [result, setResult] = useState(null);

    const serviceRequest = useCallback(async (data) => {
        setSending(true);
        const { res } = await ApiSendServiceRequest(data);
        setResult(res);
    }, []);

    return [serviceRequest, sending, result];
};

export default useSendServiceRequest;