import React from 'react';
import styles from './Footer.module.css'
import Icons from "../Icons/Icons";
import logo from '../../data/img/logo/logo-home.webp'
import {InputSubscribe} from "../index";
import FooterMenu from "./FooterMenu";
import {useLocation} from "react-router-dom";
import {BsInstagram} from "react-icons/bs";

const Footer = () => {
    const {pathname} = useLocation();
    return (
        <>
            <div className={styles.container}>
                {pathname !== "/studio" ? <>
                        <div className={styles.icons}>
                            <Icons/>
                        </div>

                        </> :
                    <div className={styles.alignCenter}>

                    <a href={"https://www.instagram.com/underbearstudio/"} target={"_blank"}>
                        <div className={styles.itemGreen}>
                            <BsInstagram size={35}/>
                        </div>
                    </a>
                    </div>
                }
                {/* <InputSubscribe/> */}
                <FooterMenu/>
                <img className={styles.logo} src={logo} alt={""}/>
                <div className={styles.credits}>
                    <div>
                        &copy; {new Date().getFullYear()} AAR Music SRL a socio unico - Via Dario Papa 4/A - 20125 Milano - P.IVA 10891610965 - AAR Music – Label | Mngmnt | Publishing
                    </div>
                    {/* <div>Product by <a href="https://davidelista.com" target="_blank" className={styles.productBy}>Davide
                        Lista</a></div> */}
                </div>

            </div>
        </>
    );
};

export default Footer;