import { useCallback, useState } from "react";
import {ApiRegister} from "../api";

const useRegister = () => {
    const [sending, setSending] = useState(false);
    const [result, setResult] = useState(null);

    const register = useCallback(async (data) => {
        setSending(true);
        const { res } = await ApiRegister(data);
        setResult(res);
    }, []);

    return [register, sending, result];
};

export default useRegister;