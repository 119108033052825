
import {useEffect, useState} from 'react';
import {ApiGetInsertCoin} from "../api";

const useGetInsertCoin = (email, page, filters) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        setInsertCoin(email, page, filters);
    }, [email, page]);

    const setInsertCoin = async (email, page) => {
        const insertCoinList = await ApiGetInsertCoin(email, page, filters);
        setState(insertCoinList);
    };
    return state;
};

export default useGetInsertCoin;
