export default function validateInfo(values) {
    let errors = {};

    /* NAME */
    if (!values.name.trim()) {
        errors.name = 'Name required';
    }

    /* SURNAME */
    if (!values.surname.trim()) {
        errors.surname = 'Surname required';
    }

    /* EMAIL */
    if (!values.email) {
        errors.email = 'Email required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }

    /* INSTAGRAM */
    if (!values.instagram) {
        errors.instagram = 'Instagram username required';
    } else if (!/@\S+/.test(values.instagram)){
        errors.instagram = 'Instagram username is invalid'
    }

    if (!values.link){
        errors.link = 'You have to insert the link of your demo';
    }

    if (!values.terms){
        errors.terms = 'You have to accept all terms before sending';
    }

    /* PASSWORD 1
    if (!values.password) {
        errors.password = 'Password is required';
    } else if (values.password.length < 6) {
        errors.password = 'Password needs to be 6 characters or more';
    }
    */
    /* PASSWORD 2
    if (!values.re_password) {
        errors.re_password = 'Password is required';
    } else if (values.re_password !== values.password) {
        errors.re_password = 'Passwords do not match';
    }
     */
    return errors;
}