import React, {useContext, useEffect, useState} from 'react';
import {Articles, ArtsistMarkdown, CarouselArtist, SideTitle, Video, Awards} from "../../components";
import {Link, useParams} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import styles from "../../components/Pages/Roster/Roster.module.css";
import ImageSlider from "../../components/Carousel/ImageSlider";
import {BallTriangle} from "react-loader-spinner";
import {BsArrowLeft} from "react-icons/bs";

const Artist = () => {
    const appData = useContext(AppContext);
    const [page, setPage] = useState('');
    const {artistName} = useParams();
    const [open, setOpen] = useState(false)

    let artist = artistName;

    useEffect(() => {

        if (artistName !== null) {
            loadArtist();
        } else {
            artist = appData.path.value.replace('/roster/', '');
            loadArtist();
        }

        if (appData.artist.data !== null) {
            import(`../../data/md/artists/${appData.artist.data.file}`)
                .then(res => {
                    fetch(res.default)
                        .then(res => res.text())
                        .then(res => setPage(res))
                        .catch(err => console.log(err));
                })
                .catch(err => console.log(err));
        }

    }, [artist, appData])

    const loadArtist = () => {
        appData.rosterList.artists.map((x, i) => {
            if (artist === x.link) {
                appData.artist.setData(x);
            }
        })
    }


    return (
        <>
            {appData.artist.data !== null ? <section>
                <div className={styles.header}>
                    <div className={styles.sideTitle}>
                        <SideTitle children={appData.artist.data.name} bgColor={appData.color.black}
                                   color={appData.color.white}/>
                    </div>

                        <div className={styles.arrowBack}> <Link to={"/roster"}><BsArrowLeft/> </Link></div>

                </div>
                <div className={styles.page}>
                    <ArtsistMarkdown page={page} artist={appData.artist.data}/>
                </div>
                {appData.artist.data.img.length > 1 ?
                    <ImageSlider artist={appData.artist.data}/>
                    : null
                }
                <div className={styles.page}>
                    {appData.artist.data.articles_name.length !== 0 ? // if there aren't any articles, don't show that session
                        <Articles artist={appData.artist.data} color={appData.color} open={open}
                                  onChangeOpen={setOpen}/> : null}
                    {appData.artist.data.awards_songs.length !== 0 ?
                        <Awards artist={appData.artist.data}/> : null}
                </div>


            </section> : <section>
                <BallTriangle
                    height={50}
                    width={50}
                    radius={5}
                    color="#4fa94d"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                />
            </section>

            }

            {appData.artist.data !== null && appData.artist.data.video.length !== 0 // if there aren't any video, don't show that session
                ? <Video artist={appData.artist.data} colorBg={appData.color.grey} title={"VIDEO"}/> : null}

        </>
    );
};

export default Artist;