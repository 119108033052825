import React, {useContext} from 'react';
import styles from './Form.module.css';
import {AppContext} from "../../context/AppContext";

const FormResponse = (props) => {
    const {children} = props;
    return (
        <section>
            <div className={styles.container}>

                <div className={styles.box}>
                    {children}
                </div>

            </div>
        </section>

    );
};

export default FormResponse;