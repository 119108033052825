import React, {useState, useContext, useEffect} from 'react';
import styles from './Popup.module.css'
import Popup from "./Popup";
import {AreaIcon, Button} from "../index";
import {AppContext} from "../../context/AppContext";
import Sections from "./Sections";
import {
    ApiCompleteRequest,
    ApiIsBot, ApiReportArtist,
    ApiSendAreaDemo,
    ApiSendGenreDemo, ApiSendLevelDown, ApiSendLevelUp,
    ApiSendMoodDemo,
    ApiSendSkillDemo
} from "../../api";
import useGetArea from "../../hooks/useGetArea";
import useGetMood from "../../hooks/useGetMood";
import useGetGenre from "../../hooks/useGetGenre";
import useGetSkill from "../../hooks/useGetSkill";
import ReportArtistPopup from "./ReportArtistPopup";
import {FaRobot, FaThumbsDown, FaThumbsUp} from "react-icons/fa";
import CompleteRequestPopup from "./CompleteRequestPopup";

const ServiceRequestsPopup = (props) => {
    const appData = useContext(AppContext);
    const {open, onChangeOpen, demo, hide} = props;

    const [openPopupComplete, setOpenPopupComplete] = useState(false);

    const [isComplete, setIsComplete] = useState({
        email: demo.email,
        service: demo.service,
    })

    const handleClick = () => {
        let setComplete = ApiCompleteRequest(isComplete);
    }

    return (
        <>
            <Popup
                open={open} onChangeOpen={onChangeOpen}>
                {
                    openPopupComplete ?
                        <CompleteRequestPopup
                            isComplete={isComplete}
                            onChangeOpenPopupComplete={setOpenPopupComplete}
                            onChangeOpen={onChangeOpen}/> : null
                }
                <div className={styles.header}>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Email
                        </div>
                        <div className={styles.text}>

                            <a href={`mailto:${demo.email}`} target={"_blank"}>{!hide ? demo.email : "**********"}</a>
                        </div>
                    </div>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Telefono
                        </div>
                        <div className={styles.text}>
                            <a href={`tel:${demo.phone_number}`}
                               target={"_blank"}>{!hide ? demo.phone_number : "**********"}</a>
                        </div>
                    </div>

                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Link
                        </div>
                        <div className={styles.text}>
                            <a href={demo.link}>Demo</a>
                        </div>
                    </div>

                </div>

                <div className={styles.header}>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Città
                        </div>
                        <div className={styles.text}>
                            <a href={`${demo.city}`}
                               target={"_blank"}>{!hide ? demo.city : "**********"}</a>
                        </div>
                    </div>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Via
                        </div>
                        <div className={styles.text}>
                            <a href={`${demo.street}`}
                               target={"_blank"}>{!hide ? demo.street : "**********"}</a>
                        </div>
                    </div>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Provincia
                        </div>
                        <div className={styles.text}>
                            <a href={`${demo.province}`}
                               target={"_blank"}>{!hide ? demo.province : "**********"}</a>
                        </div>
                    </div>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            CAP
                        </div>
                        <div className={styles.text}>
                            <a href={`${demo.cap}`}
                               target={"_blank"}>{!hide ? demo.cap : "**********"}</a>
                        </div>
                    </div>
                </div>


                <div className={styles.message}>
                    {demo.message}
                </div>

                <div className={styles.buttons}>
                    <Button onClick={() => {

                        setOpenPopupComplete(true)
                    }}>
                        COMPLETATA
                    </Button>
                </div>

            </Popup>

        </>
    )
        ;
};

export default ServiceRequestsPopup;