import React, {useContext} from 'react';
import styles from './HeaderStudio.module.css'
import {AppContext} from "../../../../context/AppContext";
import {SideTitle} from "../../../index";
import useMediaQuery, {Device} from "../../../../hooks/useMediaQuery";

const HeaderStudio = (props) => {
    const {title, linkTitle, bgImage, margin} = props;
    const isMobile = useMediaQuery(Device.sm);
    return (
        <>
            <div className={styles.container} style={{backgroundImage: `linear-gradient(0deg, rgba(14, 13, 13, 0.3), rgba(14, 13, 13, 0.3)), url(${bgImage})`, marginTop:`${margin}`}}>
                <div className={styles.section}>

                </div>


                <div className={styles.sideTitle}>
                    <SideTitle children={title} linkChildren={linkTitle} />
                </div>

                {/* { !isMobile ?
                    <div className={styles.sideTitle}>
                        <SideTitle children={title} linkChildren={linkTitle} />
                    </div> : null
                } */}


            </div>
        </>
    );
};

export default HeaderStudio;