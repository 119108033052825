import React, {useState} from 'react';
import styles from './Popup.module.css'

const Sections = (props) => {
    const {list, title, array, onChangeArray, id} = props;

    const handleChange = e => {
        const { name, checked } = e.target;

        onChangeArray({
            ...array,
            [name]: checked
        });

    };

    return (
        <>
            <div className={styles.section}>
                <div className={styles.title}>{title}</div>
                {list.map((x, i) => {
                    return (
                        <>
                            <div className={styles.row}>
                                <label>
                                    <input type={"checkbox"} id={x.value} name={x.value} value={x.value} checked={array[x.value]} onChange={(e) => {handleChange(e)}}/>
                                    {x.name}
                                </label>
                            </div>
                        </>
                    )
                })}
            </div>

        </>
    );
};

export default Sections;