import React from 'react';
import styles from "./Card.module.css";
import {Device, useMediaQuery} from "../../hooks";
import {CleanText, ExpandableParagraph, FormatDate} from "../index";

const Post = (props) => {

    const {post, isMainPost} = props;
    const isMobile = useMediaQuery(Device.sm);

    let content;
    if (isMainPost){
        content = (
            <>
                <div className={styles.mainContainer}>
                    <div className={`${styles.mainContainer} ${styles.fullImg}`}>
                        <img src={post.media_url} alt={post.caption} />
                        <div className={styles.text}>
                            <ExpandableParagraph>{post.caption}</ExpandableParagraph>
                        </div>
                        <div className={styles.date}><FormatDate date={post.timestamp}/></div>
                    </div>
                </div>
            </>
        );
    } else {
        content = (
            <>
                <div className={styles.container}>
                    <div className={`${styles.container} ${styles.fullImg}`}>
                        <img src={post.media_url} alt={post.caption}/>
                        <div className={styles.text}>
                            <ExpandableParagraph>{post.caption}</ExpandableParagraph>
                        </div>
                        <div className={styles.date}><FormatDate date={post.timestamp}/></div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );

};

export default Post;