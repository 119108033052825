import React from 'react';
import {Button} from "../index";
import {ApiCompleteRequest, ApiIsBot, ApiReportArtist} from "../../api";
import Popup from "./Popup";

const CompleteRequestPopup = (props) => {
    const {isComplete, onChangeOpenPopupComplete, onChangeOpen} = props
    return (
        <>
            <Popup> Vuoi chiudere questa richiesta? <Button onClick={() => {
                ApiCompleteRequest(isComplete);
                onChangeOpenPopupComplete(false);
                onChangeOpen(false);
                window.location.reload();
            }}> Sì</Button> <Button onClick={() => onChangeOpenPopupComplete(false)}>No</Button> </Popup>
        </>
    );
};

export default CompleteRequestPopup;