import React, {useState, useContext, useEffect} from 'react';
import styles from './Popup.module.css'
import Popup from "./Popup";
import {AreaIcon, Button} from "../index";
import {AppContext} from "../../context/AppContext";
import Sections from "./Sections";
import {
    ApiIsBot, ApiReportArtist,
    ApiSendAreaDemo,
    ApiSendGenreDemo, ApiSendLevelDown, ApiSendLevelUp,
    ApiSendMoodDemo,
    ApiSendSkillDemo
} from "../../api";
import useGetArea from "../../hooks/useGetArea";
import useGetMood from "../../hooks/useGetMood";
import useGetGenre from "../../hooks/useGetGenre";
import useGetSkill from "../../hooks/useGetSkill";
import BotPopup from "./BotPopup";
import ReportArtistPopup from "./ReportArtistPopup";
import {FaRobot, FaThumbsDown, FaThumbsUp} from "react-icons/fa";

const InsertCoinPopup = (props) => {
    const appData = useContext(AppContext);
    const {open, onChangeOpen, demo, hide} = props;
    const [checkedArea, setCheckedArea] = useGetArea(demo.id)
    const [checkedMood, setCheckedMood] = useGetMood(demo.id)
    const [checkedGenre, setCheckedGenre] = useGetGenre(demo.id)
    const [checkedSkill, setCheckedSkill] = useGetSkill(demo.id)

    const [openPopupBot, setOpenPopupBot] = useState(false);
    const [openPopupReport, setOpenPopupReport] = useState(false);

    const [isBot, setIsBot] = useState({
        email: demo.email,
        is_bot: true,
    })

    const [reportArtist, setReportArtist] = useState({
        email: demo.email,
        reported: true,
    })

    const handleClick = () => {
        let resArea = ApiSendAreaDemo(checkedArea);
        let resMood = ApiSendMoodDemo(checkedMood);
        let resGenre = ApiSendGenreDemo(checkedGenre);
        let resSkill = ApiSendSkillDemo(checkedSkill);
    }

    const handleClickLevelUp = () => {
        let resLevelUp = ApiSendLevelUp({"email": demo.email});
        let resArea = ApiSendAreaDemo(checkedArea);
        let resMood = ApiSendMoodDemo(checkedMood);
        let resGenre = ApiSendGenreDemo(checkedGenre);
        let resSkill = ApiSendSkillDemo(checkedSkill);
    }

    const handleClickLevelDown = () => {
        let resLevelDown = ApiSendLevelDown({"email": demo.email});
        let resArea = ApiSendAreaDemo(checkedArea);
        let resMood = ApiSendMoodDemo(checkedMood);
        let resGenre = ApiSendGenreDemo(checkedGenre);
        let resSkill = ApiSendSkillDemo(checkedSkill);
    }

    return (
        <>
            <Popup
                open={open} onChangeOpen={onChangeOpen}>
                <div className={styles.header}>
                        <Button onClick={() => {
                            setOpenPopupBot(true)
                        }}> <FaRobot size={25}/> </Button>

                    {
                        openPopupBot ?
                            <BotPopup isBot={isBot} onChangeOpenPopupBot={setOpenPopupBot} onChangeOpen={onChangeOpen}/> : null
                    }

                    <Button  onClick={() => {
                        setOpenPopupReport(true)
                    }}>
                        Raccomanda
                    </Button>
                    {
                        openPopupReport ?
                            <ReportArtistPopup reportArtist={reportArtist} onChangeOpenPopupReport={setOpenPopupReport} onChangeOpen={onChangeOpen} handleClick={handleClick}/> : null
                    }
                </div>

                <div className={styles.header}>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Email
                        </div>
                        <div className={styles.text}>

                            <a href={`mailto:${demo.email}`} target={"_blank"}>{!hide ? demo.email : "**********"}</a>
                        </div>
                    </div>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Instagram
                        </div>
                        <div className={styles.text}>
                            <a href={`http://instagram.com/:${demo.instagram}`} target={"_blank"}>{!hide ? demo.instagram : "**********"}</a>
                        </div>
                    </div>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Link
                        </div>
                        <div className={styles.text}>
                            <a href={demo.link} target={"_blank"}>Demo</a>
                        </div>
                    </div>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Livello
                        </div>
                        <div className={styles.text}>
                            {demo.level}
                        </div>
                    </div>
                    <div className={styles.cell}>
                        <div className={styles.title}>
                            Area
                        </div>
                        <div className={styles.text}>
                            <AreaIcon data={demo}/>
                        </div>
                    </div>
                </div>

                <div className={styles.message}>
                    {demo.message}
                </div>
                <div className={styles.card}>
                    <Sections list={appData.areaList} title={"AREA"} array={checkedArea}
                              onChangeArray={setCheckedArea}/>
                    <Sections list={appData.genreList} title={"GENRE"} array={checkedGenre}
                              onChangeArray={setCheckedGenre}/>
                    <Sections list={appData.moodList} title={"MOOD"} array={checkedMood}
                              onChangeArray={setCheckedMood}/>
                    <Sections list={appData.skillList} title={"SKILL"} array={checkedSkill}
                              onChangeArray={setCheckedSkill}/>

                </div>
                <div className={styles.title}>
                    Lascia una nota a tuo nome:
                </div>
                <textarea className={styles.textArea} rows={4} placeholder={"Scrivi qui un commento..."} />

                <div className={styles.buttons}>
                    <Button onClick={() => {
                        handleClickLevelDown();
                        onChangeOpen(!open);
                        window.location.reload();
                    }}>
                        <FaThumbsDown size={25} color={"red"}/>
                    </Button>
                    <Button onClick={() => {
                        handleClick();
                        onChangeOpen(!open);
                        window.location.reload();
                    }}>
                        SEND
                    </Button>
                    <Button onClick={() => {
                        handleClickLevelUp();
                        onChangeOpen(!open);
                        window.location.reload();
                    }}>
                        <FaThumbsUp size={25} color={'var(--green)'}/>
                    </Button>

                </div>

            </Popup>

        </>
    );
};

export default InsertCoinPopup;