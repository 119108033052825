import React from 'react';
import {CenterTitle, Form} from "../../components";

const Login = () => {
    return (
        <div>
            <section>
                <CenterTitle children={"Login"}/>
                <Form type={"login"}/>

            </section>
        </div>
    );
};

export default Login;