import React, {useEffect, useState} from 'react';
import styles from './Search.module.css'
import {AiOutlineSearch} from "react-icons/ai";

const Search = (props) => {
    const {list, backupList, onChangeList} = props;
    const [search, setSearch] = useState("");

    const handleChange = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        if (list !== null) {
            const filteredListLower = list.filter(
                (item) => item.instagram.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase()) || item.surname.toLowerCase().includes(search.toLowerCase())
            );
            if (filteredListLower.length > 0) {
                console.log("filter", filteredListLower)
                onChangeList(filteredListLower)
            }
            if(search === ""){
                onChangeList(backupList)
            }
        }
    }, [search])



    return (
        <>
            <div className={styles.searchBox}>
                <input type="text" placeholder={"Search..."} onChange={handleChange} value={search} className={styles.input}/>
                <span className={styles.icon}>
                   <AiOutlineSearch/>
               </span>

            </div>
        </>
    );
};

export default Search;