import React from 'react';
import styles from "./Input.module.css";
import {Button} from "../../index";
import useMediaQuery, {Device} from "../../../hooks/useMediaQuery";

const InputLeft = (props) => {

    const isMobile = useMediaQuery(Device.sm);
    const {input, icon, type, name, placeholder, value, onChange, error} = props;

    const typeInput = () => {

        switch (input) {
            case "input":
               return (
                    <input
                        className={error ? `${styles.formInputError} ${styles.left}` : `${styles.formInput} ${styles.left}`}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        autoComplete={"off"}
                    />
                );
            case "text-area":
                return (
                    !isMobile ? <textarea className={`${styles.formInput} ${styles.left}`}
                              name={name}
                              placeholder={placeholder}
                              value={value}
                              onChange={onChange}
                              rows={"4"}
                    /> : <textarea className={`${styles.formInput} ${styles.left}`}
                                   name={name}
                                   placeholder={placeholder}
                                   value={value}
                                   onChange={onChange}
                                   rows={"2"}
                        />
                )
        }
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.icon}>
                    {icon}
                </div>
                {typeInput()}

            </div>
        </>
    );
};

export default InputLeft;