import React from 'react';
import {Button} from "../index";
import {ApiReportArtist} from "../../api";
import Popup from "./Popup";

const ReportArtistPopup = (props) => {
    const {reportArtist, onChangeOpenPopupReport, onChangeOpen, handleClick} = props
    return (
        <>
            <Popup> Vuoi raccomandare questo artista? <Button onClick={() => {
                handleClick();
                onChangeOpenPopupReport(false);
                onChangeOpen(false);
                window.location.reload();
            }}> Sì</Button> <Button onClick={() => onChangeOpenPopupReport(false)}>No</Button> </Popup>
        </>
    );
};

export default ReportArtistPopup;