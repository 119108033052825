
import {useEffect, useState} from 'react';
import {ApiGetUsers} from "../api";

const useGetUsers = () => {
    const [state, setState] = useState(null);

    useEffect(() => {
        if(state == null){
            setUsers();
        }
    }, []);

    const setUsers = async (email) => {
        const usersList = await ApiGetUsers();
        setState(usersList.data);
    };
    return state;
};

export default useGetUsers;
