import React, {useEffect, useState} from 'react';
import validate from './ValidateInfo';
import useForm from '../../../hooks/useForm';
import styles from '../Form.module.css'
import {Button, InputLeft} from "../../index";
import {useSendInsertCoin} from "../../../hooks";
import useFormInsertCoin from "./UseFormInsertCoin";
import {BsInstagram, BsPlusLg} from "react-icons/bs";
import {BiAt, BiUserCircle} from "react-icons/bi";
import {HiMusicNote} from "react-icons/hi";
import FormResponse from "../FormResponse";
import {BallTriangle} from "react-loader-spinner";

const FormInsertCoin = (props) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const [insertCoin, sending, result] = useSendInsertCoin();
    const {handleChange, submit, values, errors, loading} = useFormInsertCoin(
        insertCoin,
        validate,
    );


    if (result === "OK") return (
        <FormResponse>
            <h3>Grazie <span style={{color: 'var(--green)'}}>{values.instagram}</span>, la tua demo è stata inviata!
            </h3>
        </FormResponse>
    );
    if (result === "LINK_NOT_VALID") return (
        <FormResponse>
            <h3>Mi dispiace <span style={{color: 'var(--green)'}}>{values.instagram}</span>, hai inserito un link non
                valido!</h3>
        </FormResponse>
    );
    if (result === "FAILED") return (
        <FormResponse>
            <h3>Mi dispiace <span style={{color: 'var(--green)'}}>{values.instagram}</span>, si è verificato un errore.
                Ricarica la pagina per inviare nuovamente la demo</h3>
        </FormResponse>
    );
    if (result === "INSTAGRAM_NOT_EXIST") return (
        <FormResponse>
            <h3>Mi dispiace ma il nome utente <span style={{color: 'var(--green)'}}>{values.instagram}</span> non
                esiste.
                Ricarica la pagina per inviare nuovamente la demo</h3>
        </FormResponse>
    );

    return (
        <>
            <div className={styles.text}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
            </div>
            <form onSubmit={submit} className={styles.form} noValidate>
                <div className={styles.container}>


                    {/*NAME*/}
                    <div className={styles.inputs}>

                        {errors.name ?

                            <InputLeft input={"input"} icon={<BiUserCircle size={20} color={"red"}/>} type={"text"}
                                       name={"name"}
                                       placeholder={errors.name} value={values.name} onChange={handleChange}
                                       error={true}/> :
                            <InputLeft input={"input"} icon={<BiUserCircle size={20}/>} type={"text"} name={"name"}
                                       placeholder={"Enter your name"} value={values.name} onChange={handleChange}/>}
                    </div>

                    {/*SURNAME*/}
                    <div className={styles.inputs}>


                        {errors.surname ?
                            <InputLeft input={"input"} icon={<BiUserCircle size={20} color={"red"}/>} type={"text"}
                                       name={"surname"}
                                       placeholder={errors.surname} value={values.surname}
                                       onChange={handleChange} error={true}/> :
                            <InputLeft input={"input"} icon={<BiUserCircle size={20}/>} type={"text"} name={"surname"}
                                       placeholder={"Enter your surname"} value={values.surname}
                                       onChange={handleChange}/>}
                    </div>

                    {/*INSTAGRAM*/}
                    <div className={styles.inputs}>

                        {errors.instagram ?
                            <InputLeft input={"input"} icon={<BsInstagram size={18} color={"red"}/>} type={"text"}
                                       name={"instagram"}
                                       placeholder={errors.instagram} value={values.instagram} error={true}
                                       onChange={handleChange}/> :
                            <InputLeft input={"input"} icon={<BsInstagram size={18}/>} type={"text"} name={"instagram"}
                                       placeholder={"Enter your instagram"} value={values.instagram}
                                       onChange={handleChange}/>}
                    </div>

                    {/*EMAIL*/}
                    <div className={styles.inputs}>
                        {errors.email ? <InputLeft input={"input"} icon={<BiAt size={20} color={"red"}/>} type={"text"}
                                                   name={"email"}
                                                   placeholder={errors.email} value={values.email}
                                                   onChange={handleChange} error={true}/> :
                            <InputLeft input={"input"} icon={<BiAt size={20}/>} type={"text"} name={"email"}
                                       placeholder={"Enter your email"} value={values.email} onChange={handleChange}/>}
                    </div>

                    <div className={styles.inputs}>


                        {errors.link ?
                            <InputLeft input={"input"} icon={<HiMusicNote size={20} color={"red"}/>} type={"text"}
                                       name={"link"}
                                       placeholder={errors.link} value={values.link} onChange={handleChange}
                                       error={true}/> :
                            <InputLeft input={"input"} icon={<HiMusicNote size={20}/>} type={"text"} name={"link"}
                                       placeholder={"Enter your link"} value={values.link} onChange={handleChange}/>}
                    </div>


                    <div className={styles.inputs}>

                        <InputLeft input={"text-area"} icon={<BsPlusLg size={18}/>} type={"text"} name={"message"}
                                   placeholder={"Write here your message"} value={values.message}
                                   onChange={handleChange}/>

                        {errors.message && <p>{errors.message}</p>}
                    </div>

                    {!loading ? <Button type='submit' disabled={sending}>
                        Insert coin
                    </Button> : <BallTriangle
                        height={50}
                        width={50}
                        radius={5}
                        color="#4fa94d"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                    }
                </div>
                <div className={styles.terms}>
                    <input type="checkbox"
                           name='terms'
                           value={values.terms}
                           onChange={handleChange}
                    />
                    <div className={styles.termsText}>
                        Acconsento ad essere ricontattato da AAR Music in merito ad info, ultime uscite ed eventi del
                        marchio. Consulta l'informativa privacy per ulteriori informazioni.
                    </div>
                </div>
                {errors.terms && <p style={{color: "red"}}>{errors.terms}</p>}

            </form>
        </>
    );
};

export default FormInsertCoin;