import {useEffect, useState} from 'react';
import {ApiGetGenre, ApiGetMood} from "../api";

const useGetGenre = (id) => {
    const [state, setState] = useState({
        "user_id": id,
        "rap": false,
        "trap": false,
        "drill": false,
        "pop": false,
        "rock_punk_popPunk": false,
        "club": false,
        "rnb": false,
        "afro": false

    });

    useEffect(() => {
        setGenre(id);

    }, []);

    const setGenre = async (id) => {
        const genreInfo = await ApiGetGenre(id);
        console.log("Area info", genreInfo);
        if(genreInfo !== null){
            setState(genreInfo.data);
        } else {
            setState({
                "user_id": id,
                "rap": false,
                "trap": false,
                "drill": false,
                "pop": false,
                "rock_punk_popPunk": false,
                "club": false,
                "rnb": false,
                "afro": false

            })
        }

    };
    return [state, setState];
};

export default useGetGenre;
