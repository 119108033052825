import React, {useContext, useEffect, useState} from 'react';
import validate from './ValidateInfo';
import useForm from '../../../hooks/useForm';
import styles from '../Form.module.css'
import {Button, InputLeft, Dropdown} from "../../index";
import {useSendServiceRequest} from "../../../hooks";
import useFormServices from "./UseFormServices";
import {BsInstagram, BsPhone, BsPlusLg} from "react-icons/bs";
import {BiAt, BiUserCircle} from "react-icons/bi";
import FormResponse from "../FormResponse";
import {HiMusicNote, HiOutlineReceiptTax} from "react-icons/hi";
import {BallTriangle} from "react-loader-spinner";
import {Link} from "react-router-dom";
import convertHtmlToReact from "@hedgedoc/html-to-react";
import {MdPhoneIphone} from "react-icons/md";


const FormServices = (props) => {
    const {item} = props;
    const [serviceRequest, sending, result] = useSendServiceRequest();
    const {handleChange, submit, values, errors, loading} = useFormServices(
        serviceRequest,
        validate,
    );

    const [idCategory, setIdCategory] = useState(-1)

        

    if (result === "OK") return (
        <FormResponse>
            <h3>Grazie <span style={{color: 'var(--green)'}}>{values.instagram}</span>, la tua mail è stata inviata!
            </h3>
            <br/>
            <center><Link to={"/"}><Button>Home</Button></Link></center>
        </FormResponse>
    );
    if (result === "LINK_NOT_VALID") return (
        <FormResponse>
            <h3>Mi dispiace <span style={{color: 'var(--green)'}}>{values.instagram}</span>, il link non è valido!
            </h3>
            <br/>
            <center><Link to={"/studio"}><Button className={"green"}>Ricarica</Button></Link></center>
        </FormResponse>
    );
    if (result === "REQUEST_ALREADY_DONE") return (
        <FormResponse>
            <h3>Grazie <span style={{color: 'var(--green)'}}>{values.instagram}</span>, ma hai già fatto questa
                richiesta.<br/>Il nostro team ti contatterà a breve.<br/>Ti ringraziamo per la pazienza.
            </h3>
            <br/>
            <center><Link to={"/"}><Button className={"green"}>Home</Button></Link></center>
        </FormResponse>
    );
    if (result === "FAILED") return (
        <FormResponse>
            <h3>Mi dispiace <span style={{color: 'var(--green)'}}>{values.instagram}</span>, si è verificato un errore!
            </h3>
            <br/>
            <center><Button className={"green"} onClick={()=>{window.location.reload()}}>Ricarica</Button></center>
        </FormResponse>
    );

    
    return (
        <>
            <form onSubmit={submit} className={styles.form} noValidate>

                {item !== null ? 
                    <>
                        <div className={styles.text}>
                            {convertHtmlToReact(item.description, true)}
                        </div>
                        {
                            item.categories.length > 0 ?
                                <Dropdown placeholder={item.placeholder} itemList={item.categories} value={values.service} linkConfirmation={item.linkConferma}
                                        onChange={handleChange}/>: <div style={{display: "none"}}></div>
                        } 
                    </> 
                        : <BallTriangle
                            height={50}
                            width={50}
                            radius={5}
                            color="#4fa94d"
                            ariaLabel="ball-triangle-loading"
                            wrapperClass={{}}
                            wrapperStyle=""
                            visible={true}
                        />
                }
                {/* {values.service ? 
                    <> */}
                        {/* <div className={styles.container}>

                            
                        </div> */}
                        {/* <div className={styles.terms}>
                            <input type="checkbox"
                                   name='terms'
                                   value={values.terms}
                                   onChange={handleChange}
                            />
                            <div className={styles.termsText}>
                                Acconsento ad essere ricontattato da AAR Music in merito ad info, ultime uscite ed eventi
                                del
                                marchio. Consulta l'informativa privacy per ulteriori informazioni.
                            </div>
                        </div> */}
                        {/* {errors.terms && <p style={{color: "red"}}>{errors.terms}</p>} */}
                    {/* </> */}
                {/* : null} */}
            </form>
        </>
    );
};

export default FormServices;