import React, { useState } from 'react';
import styles from './Form.module.css';
import FormInsertCoin from "./InsertCoin/FormInsertCoin";
import FormLogin from "./Login/FormLogin";
import FormRegister from "./Register/FormRegister";
import {Link} from "react-router-dom";
import FormContacts from "./Contacts/FormContacts";
import FormServices from "./Services/FormServices";


const Form = (props) => {
    const {type, item} = props;

    const typeForm = (item) => {
        switch (type){
            case "insert-coin":
                return <FormInsertCoin />;
            case "login":
                return <FormLogin />;
            case "register":
                return <FormRegister />;
            case "contacts":
                return <FormContacts />;
            case "services":
                return <FormServices item={item} />;
            default:
                return <center><div>Error</div></center>
        }
    }




    return (
        <>
            <div className={styles.formServices}>
                {typeForm(item)}
            </div>

            <div className={styles.help}>
                Ti serve aiuto? Vai alle <Link to={'/faq'}>FAQ</Link>
            </div>
        </>
    );
};

export default Form;