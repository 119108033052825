import React, {useRef} from 'react';
import styles from './Card.module.css'
import convertHtmlToReact from "@hedgedoc/html-to-react";


const ServiceCard = (props) => {
    const {img, name, text, onChangeService, id, reference, selected} = props

    let importImg = require('../../data/img/studio/' + img);


    const handleClick = () => {
        reference.current.scrollIntoView({ block: "start", behavior: 'smooth'});
    };
    return (
        <>

            <div className={styles.services} onClick={() => {
                onChangeService(id);
                handleClick();
            }}>

                <img src={importImg} alt={name} className={styles.serviceImage} style={selected !== id && selected !== -1 ? {opacity: 0.5} : null}/>
                <h2 className={selected !== id ? styles.title : styles.activeTitle}>{name}</h2>
                {/* <div className={styles.serviceText}>{convertHtmlToReact(text, true)}</div> */}
            </div>

        </>
    );
};

export default ServiceCard;