import React from 'react';
import {GetServiceRequests} from "../../components";
import withAuth from "../../components/hoc/withAuth";

const ManageServiceRequests = () => {
    return (
        <>
            <GetServiceRequests/>
        </>
    );
};

export default withAuth(ManageServiceRequests);