import React, {useRef, useState, useEffect} from 'react';
import useMediaQuery, {Device} from "../../hooks/useMediaQuery";

const ResizeText = (props) => {
    const {lines = 1} = props
    const title = useRef(null)
    const [lh, setLh] = useState(null);
    const [font, setFont] = useState(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [oldWidth, setOldWidth] = useState(null)
    const isMobile = useMediaQuery(Device.sm);

    useEffect(() => {
            let cb = function () {
                setOldWidth(windowWidth);
                setWindowWidth(window.innerWidth);
            };
            window.addEventListener("resize", cb);
            const lh = window.getComputedStyle(title.current).lineHeight;
            //console.log("sh", title.current.scrollHeight);
            //console.log("lh", window.getComputedStyle(title.current).lineHeight);
            if (title.current === null) return; //title.current è un json

            if (lh === "normal") setLh("1.2em");


            if(windowWidth <= 762 ){
                setFont(45)
            }
            else if( windowWidth/40 < 45){
                setFont(windowWidth/40)
            } else {
                setFont(45)
            }

    }, [lines, lh, font, windowWidth, oldWidth]);

    return (
        <>
            <h1 style={!isMobile ? {fontSize: `${font}px`} : null} ref={title} >
                {props.children}
            </h1>
        </>
    );
};

export default ResizeText;