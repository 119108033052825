import React from 'react';
import styles from "./Popup.module.css"
import {GrClose} from "react-icons/gr";

function Popup(props) {
    const {title, children, open, onChangeOpen} = props;
    return (
        <>
            <div>
                <div className={styles.overlay}>
                    <div className={styles.container}>
                        <div className={styles.closeSection}>
                            <span className={styles.closeButton} onClick={() => onChangeOpen(!open)}><GrClose size={20}/></span>
                        </div>
                        <div className={styles.body}>
                            {children}
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Popup;