import React from 'react';
import styles from './CenterTitle.module.css'

const CenterTitle = (props) => {
    const {children} = props
    return (
        <>
            <div className={styles.container}>
                <h1> {children} </h1>
            </div>

        </>
    );
};

export default CenterTitle;