export default function validateInfo(values) {
    let errors = {};

    /* EMAIL */
    if (!values.email) {
        errors.email = 'Email required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }

    /* PASSWORD */
    if (!values.password) {
        errors.password = 'Password is required';
    } else if (values.password.length < 6) {
        errors.password = 'Password needs to be 6 characters or more';
    }
    if (!values.remember_me){
        errors.remember_me = 'You have to accept all terms before sending';
    }

    /* PASSWORD 2
    if (!values.re_password) {
        errors.re_password = 'Password is required';
    } else if (values.re_password !== values.password) {
        errors.re_password = 'Passwords do not match';
    }
     */
    return errors;
}