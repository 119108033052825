import React, {useContext, useEffect, useState} from 'react';
import {Table} from "../../index";
import {AppContext} from "../../../context/AppContext";
import styles from "./Admin.module.css";
import useGetServiceRequests from "../../../hooks/useGetServiceRequests";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";

const GetServiceRequests = () => {
    const appData = useContext(AppContext);
    const [page, setPage] = useState(1)
    const requestsList = useGetServiceRequests(page);
    const nextPage = useGetServiceRequests(page+1);

    return (
        <>

            <div className={styles.container}>

            {requestsList != null ? <> <Table data={requestsList} titles={appData.serviceRequestsTitlesTable} popup={"service-requests"}/>  <div className={styles.pages}>
                {page !== 1 ? <IoIosArrowBack onClick={() => setPage(page-1)} className={styles.arrow}/> : <div className={styles.arrow}/> }
                {page}
                {nextPage !== null ? <IoIosArrowForward onClick={() => setPage(page+1)} className={styles.arrow}/> : <div className={styles.arrow}/>}

            </div> </>
                : null}
            </div>
        </>
    );
};

export default GetServiceRequests;