export default function validateInfo(values) {
    let errors = {};

    /* NAME */
    if (!values.name.trim()) {
        errors.name = 'Name required';
    }

    /* SURNAME */
    if (!values.surname.trim()) {
        errors.surname = 'Surname required';
    }

    /* EMAIL */
    if (!values.email) {
        errors.email = 'Email required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }

    /* FISCAL CODE */
    if (!values.fiscal_code) {
        errors.fiscal_code = 'Fiscal code or P.IVA is required';
    }

    /* PHONE NUMBER */
    if (!values.phone_number) {
        errors.phone_number = 'Phone number is required';
    } else if(!/^\d+$/){
        errors.phone_number = 'Phone number is only number';
    } else if(values.phone_number.length !== 10){
        errors.phone_number = 'Phone number isn\'t correct';
    }

    /* STREET */
    if (!values.street) {
        errors.street = 'Street is required';
    }

    /* CITY */
    if (!values.city) {
        errors.city = 'City is required';
    }

    /* CITY */
    if (!values.province) {
        errors.province = 'Province is required';
    } else if(values.province.length !== 2){
        errors.province = 'Province isn\'t correct'
    }

    /* CAP */
    if (!values.cap) {
        errors.cap = 'CAP is required';
    } else if (!/^\d+$/){
        errors.cap = 'CAP is only number';
    } else if (values.cap.length !== 5){
        errors.cap = 'CAP isn\'t correct';
    }

    if (!values.link){
        errors.link = 'You have to insert the link of your stems';
    }

    if (!values.terms){
        errors.terms = 'You have to accept all terms before sending';
    }

    /* PASSWORD 1
    if (!values.password) {
        errors.password = 'Password is required';
    } else if (values.password.length < 6) {
        errors.password = 'Password needs to be 6 characters or more';
    }
    */
    /* PASSWORD 2
    if (!values.re_password) {
        errors.re_password = 'Password is required';
    } else if (values.re_password !== values.password) {
        errors.re_password = 'Passwords do not match';
    }
     */
    return errors;
}