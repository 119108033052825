import React, {useState, useContext} from 'react';
import {AppContext} from "../../context/AppContext";
import {Link, useLocation} from "react-router-dom";
import styles from "./Footer.module.css";

const FooterMenu = () => {
    const {pathname} = useLocation();
    const appData = useContext(AppContext);
    return (
        <>
            <div className={styles.menu}>
                {
                    appData.footerMenuList.map((x, i) => {
                        return (

                            <Link to={`${x.link}`} key={i}>
                                <div className={styles.item}
                                     style={pathname === x.link ? {color: appData.color.green} : null}
                                >
                                    {x.name}

                                </div>
                            </Link>
                        )
                    })
                }
            </div>

        </>
    )


};

export default FooterMenu;