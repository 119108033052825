import React, {useState} from 'react';
import styles from './Table.module.css'
import {AreaIcon, ToggleSwitch} from "../index";
import {TbEyeCheck} from "react-icons/tb";
import {FaSeedling, FaThumbsDown} from "react-icons/fa";
import {ApiChangeStatusActive, ApiChangeStatusAdmin} from "../../api";

const RowDetails = (props) => {
    const {row, titles, open, onChangeOpen, onChangeDemo, hide} = props;

    const handleChangeAdmin = (data) => {
        const res = ApiChangeStatusAdmin(data);
        window.location.reload()
    }
    const handleChangeActive = (data) => {
        const res = ApiChangeStatusActive(data);
        window.location.reload()
    }

    const colSize = (title, data) => {
        switch (title.size) {
            case "xsmall":
                switch (title.response) {
                    case "status":
                        if (data["level"] === 1){
                            return (
                                <div className={styles.xsmallCol} >
                                    <FaSeedling size={25} color={"black"}/>
                                </div>
                            )
                        }
                        else if (data["level"] === -1){
                            return (
                                <div className={styles.xsmallCol} >
                                    <FaThumbsDown size={25} color={"black"}/>
                                </div>
                            )
                        }
                        else {
                            if (data["seen"]){
                            return (
                                <div className={styles.xsmallCol}>
                                    <TbEyeCheck size={25} color={"black"}/>
                                </div>)
                            }
                            else {
                                return (
                                    <div className={styles.xsmallCol}>

                                    </div>)
                            }
                        }

                    default:
                        return (
                            <div className={styles.xsmallCol}>

                            </div>)
                }
            case "small":
                switch (title.response) {
                    case "link":
                        return <a href={data[title.response]} className={styles.smallCol}
                                  >Demo</a>
                    case "area":
                        return (
                            <div className={styles.smallCol} >
                                <AreaIcon data={data}/>
                            </div>
                        )
                    case "admin":
                        return(
                            <div className={styles.smallCol}  >
                                <ToggleSwitch checked={data[title.response]} onClick={() => handleChangeAdmin({"email": data["email"], "admin": !data[title.response]})}/>
                            </div>
                        )
                    case "active":
                        return(
                            <div className={styles.smallCol} >
                                <ToggleSwitch checked={data[title.response]} onClick={() => handleChangeActive({"email": data["email"], "active": !data[title.response]})}/>
                            </div>
                        )
                    default:
                        return <div className={styles.smallCol} >{data[title.response]}</div>
                }
            case "medium":
                return <div className={styles.mediumCol}
                            >{!hide ? data[title.response] : "**********"}</div>
            case "large":
                return <div className={styles.largeCol}>{data[title.response]}</div>
        }
    }

    return (

        <>

            {row !== undefined ? row.map((data, i) => {

                return (

                    <li className={!data.reported ? styles.tableRow : styles.tableRowReported} onClick={() => {
                        onChangeOpen(!open);
                        onChangeDemo(data);

                    }}>
                        {titles.map((title, j) => {
                            return colSize(title, data)
                        })}

                    </li>
                )
            }): null}
        </>
    );
};

export default RowDetails;