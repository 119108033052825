import React from 'react';
import styles from './Banner.module.css'
import fogliaGreen from '../../data/img/fogliolina-green.png'
import fogliaGrey from '../../data/img/fogliolina-grey.png'
import Button from "../Button/Button";

const Banner = (props) => {
    const {children, button, colorBg=null, height=null, bgSize=null, bgPosY=null} = props;
    return (
        <>
            <div className={styles.container}>
                <div className={styles.top} style={{backgroundSize: `${bgSize}`, backgroundPositionY: `${bgPosY}`}}>
                    <img src={colorBg !== null ? fogliaGrey : fogliaGreen} className={styles.img}/>
                    <div className={styles.space}/>
                </div>
                <div className={styles.bottom} style={colorBg !== undefined ? {background: colorBg} : null} > {/*Da aggiungere variabile background se anche diverso da verde*/}
                    {children}
                </div>

            </div>
        </>
    );
};

export default Banner;