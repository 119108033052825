import React from 'react';
import styles from './Card.module.css'
import {Device, useMediaQuery} from "../../hooks";

const OverlayCard = (props) => {
    const {img, text} = props;

    let importImg = require('../../data/img/artists/' + img);
    const isMobile = useMediaQuery(Device.sm);
    const isTablet = useMediaQuery(Device.lg)
    return (
        <>
            <div className={styles.container}>
                <div className={`${styles.container} ${styles.fullImg}`}>
                    <img src={importImg} alt={""}/>

                    <h1 className={styles.overlay}>
                            {text}
                        </h1>
                </div>

            </div>
        </>
    );
};

export default OverlayCard;