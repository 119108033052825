import React from 'react';
import { FaTiktok } from "react-icons/fa";
import styles from './Icons.module.css'
import {BsFacebook, BsInstagram, BsSpotify, BsTelegram, BsTwitch, BsYoutube} from "react-icons/bs";

/**
 * Component for the icons in mobile menu
 * @returns {JSX.Element}
 * @constructor
 */

// TODO: INSERIRE I LINK

const Icons = () => {
    let size = 35;
    return (
        <div className={styles.icons}>

            <a href={"https://www.youtube.com/channel/UCxt7MfWX0IU3Lnz5QE6pXcw"} target={"_blank"} title={"Youtube"}>
                <div className={styles.item}>
                    <BsYoutube size={size}/>
                </div>
            </a>
            <a href={"https://www.instagram.com/aarmusiclabel/"} target={"_blank"}>
                <div className={styles.item}>
                    <BsInstagram size={size}/>
                </div>
            </a>
            <a href={"https://open.spotify.com/playlist/0FWOK5ubCCTMr0JyNM0tFF?si=Z4pHmB5WTn-aKODiNloqow&nd=1"} target={"_blank"} title={"Spotify"}>
                <div className={styles.item}>
                    <BsSpotify size={size}/>
                </div>
            </a>
            <a href={"https://tiktok.com/@aarmusiclabel"} target={"_blank"} title={"Tiktok"}>
                <div className={styles.item}>
                    <FaTiktok size={size}/>
                </div>
            </a>
            <a href={"https://www.twitch.tv/aarmusic"} target={"_blank"} title={"Twitch"}>
                <div className={styles.item}>
                    <BsTwitch size={size}/>
                </div>
            </a>
            {/* <a href={"https://t.me/aarfamily"} target={"_blank"} title={"Telegram"}>
                <div className={styles.item}>
                    <BsTelegram size={size}/>
                </div>
            </a> */}

        </div>
    );
};

export default Icons;