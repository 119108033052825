import React from 'react';
import styles from "../Roster/Roster.module.css";
import {CenterTitle, Details, SideTitle, Team} from "../../components";

const Contatti = () => {
    return (
        <>
            <helmet>
                <meta name="description" content="Per richieste di collaborazioni, informazioni, sync o altro scrivi a info@aarmusic.it" />
            </helmet>
            <section style={{height:"auto"}}>
                <CenterTitle>
                    Contatti
                </CenterTitle>
                <Details/>
                <SideTitle children={"STORIA & TEAM"}/>
                <br />
                <div className={styles.text}>
                    
                    <p><b>AAR Music</b> viene fondata dal produttore musicale Eiemgei nel 2018, inizialmente come semplice canale YouTube spinto all'epoca dagli youtubers Arcade Boyz, allo scopo di lanciare e promuovere progetti artistici emergenti.
                    Nel 2020 le strade tra gli youtubers e Eiemgei si separano e quest’ultimo prosegue l’attività trasformandola in una vera e propria agenzia di management e consulenza, etichetta e società editoriale. </p>

                    <center><h3>Autonomous Artists Revenge</h3></center>

                    <p>La visione di <b>AAR</b>, come rappresentato anche dal germoglio sul logo, è di investire sugli artisti giovani e provenienti dalle realtà provinciali e maggiormente isolate d’Italia, 
                        piantando semi sparsi nel territorio e accompagnando in modo naturale lo sviluppo di ciascun progetto senza ricorrere ad acceleratori, 
                        “doping” di numeri e altre strategie tipiche dei competitors.  L’etichetta ha attualmente sede a Milano.</p>
                    
                    <p>L’<a href={"https://insertcoin.aarmusic.it"} className={styles.link}>Insert Coin</a> è il modo per avere un primo contatto con <b>AAR</b> da parte di qualunque artista emergente: 
                    inserendo i propri dati sul sito ufficiale di AAR Music si può condividere con la label i prodotti del suo talento. 
                    La label ha poi a disposizione un’app proprietaria che agevola l’attività di scouting, permettendo di ascoltare le demo, taggare i profili e rispondere a tutti rapidamente.</p>

                    <p>Gli artisti selezionati vengono pubblicati all’interno della rubrica "New Challengers” sul canale youtube della label (ad oggi oltre 35K iscritti), 
                        che ha ospitato i primi video ufficiali di artisti come <b>Madame</b>, <b>Leon Faun</b>, <b>gIANMARIA</b>, di fatto lanciandoli. 
                        Oggi il team di <b>AAR</b> è composto, oltre da Eiemgei, da <i>Antonio Pace</i>, A&R e Project Manager, e <i>False</i>, Producer e Engineer dell'Underbear Studio di Milano. 
                        Consideriamo anche parte del team tutti i producer, autori, manager, grafici, videomaker, amici e ovviamente gli artisti che ruotano attorno al nostro ecosistema e che ci rendono ogni giorno orgogliosi 🌱</p>

                </div>
                <br />
                <Team/>
            </section>
        </>
    );
};

export default Contatti;