import axios from "axios";
import ApiResponse from "./ApiResponse";

/* --- Subscribe to newsletter --- */
export const ApiSubscribeResponse = new ApiResponse("OK", "EMAIL_NOT_VALID", "FAILED");
export const ApiSubscribeRequest = async (data) => {

    return axios
        .post("/api/subscribe", data)
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSubscribeResponse.OK };
                default:
                    return { res: ApiSubscribeResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiSubscribeResponse.FAILED };
                case 501:
                    return { res: ApiSubscribeResponse.EMAIL_NOT_VALID };
                default:
                    return { res: ApiSubscribeResponse.FAILED };

            }
        });
}


/* --- Send Service Request --- */
export const ApiSendServiceRequestResponse = new ApiResponse("OK", "LINK_NOT_VALID", "FAILED", "INSTAGRAM_NOT_EXIST", "REQUEST_ALREADY_DONE");
export const ApiSendServiceRequest = async (data) => {

    return axios
        .post("/api/services/send", data)
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendServiceRequestResponse.OK };
                default:
                    return { res: ApiSendServiceRequestResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiSendServiceRequestResponse.FAILED };
                case 501:
                    return { res: ApiSendServiceRequestResponse.LINK_NOT_VALID };
                case 502:
                    return { res: ApiSendServiceRequestResponse.INSTAGRAM_NOT_EXIST };
                case 503:
                    return { res: ApiSendServiceRequestResponse.REQUEST_ALREADY_DONE };
                default:
                    console.log(err);
                    return { res: ApiSendServiceRequestResponse.FAILED };

            }
        });
}

/* --- ADMIN - Get Service Requests --- */
export const ApiGetServiceRequests = async (page) => {

    try {
        const res = await axios.get("/api/admin/get_service_requests", { withCredentials: true, params: {page}});
        console.log(res)
        return res.data.data;

    } catch (err) {
        const { status } = err.response;
        switch (status) {
            case 400:
                return "NOT_LOGGED_IN" ;
            default:
                return "ERROR";
        }
    };
};

/* --- ADMIN - Complete request --- */
export const ApiCompleteRequest = async (data) => {
    return axios
        .post("/api/admin/complete_request", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendDemoResponse.OK };
                default:
                    return { res: ApiSendDemoResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiSendDemoResponse.FAILED };
                default:
                    return { res: ApiSendDemoResponse.FAILED };
            }
        });
}

/* --- Send Insert Coin --- */
export const ApiSendInsertCoinResponse = new ApiResponse("OK", "LINK_NOT_VALID", "FAILED", "INSTAGRAM_NOT_EXIST");
export const ApiSendInsertCoin = async (data) => {

    return axios
        .post("/api/send", data)
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendInsertCoinResponse.OK };
                default:
                    return { res: ApiSendInsertCoinResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiSendInsertCoinResponse.FAILED };
                case 501:
                    return { res: ApiSendInsertCoinResponse.LINK_NOT_VALID };
                case 502:
                    return { res: ApiSendInsertCoinResponse.INSTAGRAM_NOT_EXIST };
                default:
                    return { res: ApiSendInsertCoinResponse.FAILED };
            }
        });
}

/* --- ADMIN - Get Insert Coin --- */
export const ApiGetInsertCoin = async (email, page, filters) => {

    try {
        const res = await axios.get("/api/admin", {params: {email, page, filters: filters}});
        return res.data.data;
    } catch (err) {
        const { status } = err.response;
        switch (status) {
            case 400:
                return "NOT_LOGGED_IN" ;
            default:
                return "ERROR";
        }
    };
};

export const ApiGetFilteredInsertCoin = async (page) => {

    try {
        const res = await axios.get("/api/admin/get_level_up", {withCredentials: true, params: {page}});
        return res.data;
    } catch (err) {
        const { status } = err.response;
        switch (status) {
            case 400:
                return "NOT_LOGGED_IN" ;
            default:
                return "ERROR";
        }
    };
};


/* --- ADMIN - Define isBot --- */
export const ApiIsBot = async (data) => {
    return axios
        .post("/api/admin/is_bot", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendDemoResponse.OK };
                default:
                    return { res: ApiSendDemoResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiSendDemoResponse.FAILED };
                default:
                    return { res: ApiSendDemoResponse.FAILED };
            }
        });
}

/* --- ADMIN - Report artist --- */
export const ApiReportArtist = async (data) => {
    return axios
        .post("/api/admin/report_artist", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendDemoResponse.OK };
                default:
                    return { res: ApiSendDemoResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiSendDemoResponse.FAILED };
                default:
                    return { res: ApiSendDemoResponse.FAILED };
            }
        });
}

/* --- ADMIN - Area / Mood / Genre / Skill of the demo --- */
export const ApiGetArea = async (id) => {

    try {
        const res = await axios.get("/api/admin/get_area", {params: {id}});
        return res.data;
    } catch (e) {
        if (e != null) {
            return null;
        }
        throw e;
    }
}

export const ApiGetMood = async (id) => {

    try {
        const res = await axios.get("/api/admin/get_mood", {params: {id}});
        return res.data;
    } catch (e) {
        if (e != null) {
            return null;
        }
        throw e;
    }
}

export const ApiGetGenre = async (id) => {

    try {
        const res = await axios.get("/api/admin/get_genre", {params: {id}});
        return res.data;
    } catch (e) {
        if (e != null) {
            return null;
        }
        throw e;
    }
}

export const ApiGetSkill = async (id) => {

    try {
        const res = await axios.get("/api/admin/get_skill", {params: {id}});
        return res.data;
    } catch (e) {
        if (e != null) {
            return null;
        }
        throw e;
    }
}

/* --- ADMIN - Get Users List --- */
export const ApiGetUsers = async (email) => {

    try {
        const res = await axios.get("/api/admin/get_users", { withCredentials: true });
        return res.data;
    } catch (err) {
        const { status } = err.response;
        switch (status) {
            case 400:
                return "NOT_LOGGED_IN" ;
            default:
                return "ERROR";
        }
    };
};

export const ApiChangeStatusAdmin = async (data) => {

    return axios
        .post("/api/admin/change_admin_status", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendUpdateLevelResponse.OK };
                default:
                    return { res: ApiSendUpdateLevelResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 401:
                    return { res: ApiSendUpdateLevelResponse.NOT_ADMIN };
                case 500:
                    return { res: ApiSendUpdateLevelResponse.FAILED };
                default:
                    return { res: ApiSendUpdateLevelResponse.FAILED };
            }
        });
}

export const ApiChangeStatusActive = async (data) => {

    return axios
        .post("/api/admin/change_active_status", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendUpdateLevelResponse.OK };
                default:
                    return { res: ApiSendUpdateLevelResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 401:
                    return { res: ApiSendUpdateLevelResponse.NOT_ADMIN };
                case 500:
                    return { res: ApiSendUpdateLevelResponse.FAILED };
                default:
                    return { res: ApiSendUpdateLevelResponse.FAILED };
            }
        });
}

/* --- ADMIN - Define Area / Mood / Genre / Skill of the demo */
export const ApiSendDemoResponse = new ApiResponse("OK", "FAILED");
export const ApiSendAreaDemo = async (data) => {

    return axios
        .post("/api/admin/area", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendDemoResponse.OK };
                default:
                    return { res: ApiSendDemoResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiSendDemoResponse.FAILED };
                default:
                    return { res: ApiSendDemoResponse.FAILED };
            }
        });
}

export const ApiSendMoodDemoResponse = new ApiResponse("OK", "FAILED");
export const ApiSendMoodDemo = async (data) => {

    return axios
        .post("/api/admin/mood", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendDemoResponse.OK };
                default:
                    return { res: ApiSendDemoResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiSendDemoResponse.FAILED };
                default:
                    return { res: ApiSendDemoResponse.FAILED };
            }
        });
}

export const ApiSendGenreDemo = async (data) => {

    return axios
        .post("/api/admin/genre", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendDemoResponse.OK };
                default:
                    return { res: ApiSendDemoResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiSendDemoResponse.FAILED };
                default:
                    return { res: ApiSendDemoResponse.FAILED };
            }
        });
}

export const ApiSendSkillDemo = async (data) => {

    return axios
        .post("/api/admin/skill", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendDemoResponse.OK };
                default:
                    return { res: ApiSendDemoResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiSendDemoResponse.FAILED };
                default:
                    return { res: ApiSendDemoResponse.FAILED };
            }
        });
}
export const ApiSendUpdateLevelResponse = new ApiResponse("OK", "NOT_ADMIN", "FAILED");
export const ApiSendLevelUp = async (data) => {

    return axios
        .post("/api/admin/level_up", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendUpdateLevelResponse.OK };
                default:
                    return { res: ApiSendUpdateLevelResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 401:
                    return { res: ApiSendUpdateLevelResponse.NOT_ADMIN };
                case 500:
                    return { res: ApiSendUpdateLevelResponse.FAILED };
                default:
                    return { res: ApiSendUpdateLevelResponse.FAILED };
            }
        });
}

export const ApiSendLevelDown = async (data) => {

    return axios
        .post("/api/admin/level_down", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiSendUpdateLevelResponse.OK };
                default:
                    return { res: ApiSendUpdateLevelResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 401:
                    return { res: ApiSendUpdateLevelResponse.NOT_ADMIN };
                case 500:
                    return { res: ApiSendUpdateLevelResponse.FAILED };
                default:
                    return { res: ApiSendUpdateLevelResponse.FAILED };
            }
        });
}

/* --- ADMIN - Register --- */
export const ApiRegisterResponse = new ApiResponse("OK", "USER_ALREADY_EXIST", "NOT_ADMIN", "FAILED");
export const ApiRegister = async (data) => {

    return axios
        .post("/api/admin/register", data, { withCredentials: true })
        .then((res) => {
            switch (res.status) {
                case 200:
                    return { res: ApiRegisterResponse.OK };
                case 202:
                    return { res: ApiRegisterResponse.USER_ALREADY_EXIST };
                default:
                    return { res: ApiRegisterResponse.OK };

            }
        })
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 401:
                    return { res: ApiRegisterResponse.NOT_ADMIN};
                case 500:
                    return { res: ApiRegisterResponse.FAILED };
                default:
                    return { res: ApiRegisterResponse.FAILED };
            }
        });
}

/* --- ADMIN - Login --- */
export const ApiLoginResponse = new ApiResponse("OK", "USER_NOT_EXIST", "FAILED");
export const ApiLogin = async (data) => {

    return axios
        .post("/api/admin/login", data, { withCredentials: true })
        .then((res) => ({
            res: ApiLoginResponse.OK,
            data: res.data,
        }))
        .catch((err) => {
            const { status } = err.response;
            switch (status) {
                case 500:
                    return { res: ApiLoginResponse.FAILED };
                case 406:
                    return { res: ApiLoginResponse.USER_NOT_EXIST};
                default:
                    return { res: ApiLoginResponse.FAILED };
            }
        });
}

export const ApiValidateSessionResponse = new ApiResponse("OK", "UNAUTH");
export const ApiValidateSession = async () => {
    return axios
        .get("/api/get_cookies", { withCredentials: true })
        .then((res) => ({
            res: ApiValidateSessionResponse.OK,
            data: res.data,
        }))
        .catch(() => ({
            res: ApiValidateSessionResponse.UNAUTH,
            data: null,
        }));
};

