import React, {useContext} from 'react';
import styles from './Header.module.css'
import logoHome from '../../../../data/img/logo/logo-home.webp'
import {BsChevronDown} from "react-icons/bs";
import {AppContext} from "../../../../context/AppContext";
import videoBg from "../../../../data/videos/header-video.mp4";

import {Link} from 'react-scroll';

const Header = () => {
    return (
        <>
            <div className={styles.container}>

                <video src={videoBg} autoPlay loop muted playsInline />

                <div className={styles.section}>
                    <img className={styles.logoHome} src={logoHome}/>
                <Link to='case-history' spy={true} smooth={true} offset={-120} duration={700} delay={300}>

                    <div className={styles.scrollDown}>
                        <div>SCROLL DOWN</div>
                        <BsChevronDown className={styles.arrowDown} size={40}/>
                    </div>
                </Link>

                </div>
            </div>
        </>
    );
};

export default Header;