import React from 'react';
import styles from './Faq.module.css';
import Seme from '../../../data/img/seme.png';
import FogliolinaGreen from '../../../data/img/fogliolina-green.png';

const Question = (props) => {
    const {children, id, openId, onChangeOpenId} = props;
    return (
        <>
            <div className={openId === id ? styles.questionActive : styles.question} onClick={ () => {
                if(openId === id) {
                    onChangeOpenId(null)
                } else {
                    onChangeOpenId(id)
                }

            }}>
                <div className={styles.icon}>
                    {openId !== id ? <img src={Seme} alt={"seme"} className={styles.img}/> : <img src={FogliolinaGreen} alt={"fogliolina"} className={styles.imgActive}/>}
                </div>
                <div className={styles.text}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Question;