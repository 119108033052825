import React, { useEffect, useRef, useState } from "react";
import "./ExpandableParagraph.css";

export default function ExpandableParagraph(props) {
    const { lines = 1, expandText = "Visualizza altro", collapseText = "Visualizza meno" } = props;

    const paragraph = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [height, setHeight] = useState("0px");
    const [hideButton, setHideButton] = useState(false);
    const [lh, setLh] = useState(null);
    useEffect(() => {
        if (paragraph.current === null) return; //paragraph.current è un json
        if (!expanded) {
            const lh = window.getComputedStyle(paragraph.current).lineHeight;
            if (lh === "normal" ) setLh("1.2em");
            if (lines * lh.replace(/px/g, "") < paragraph.current.scrollHeight){
                setHeight(`calc(${lines} * ${lh})`);

            }
            else {
                setHeight(paragraph.current.scrollHeight + "px");
                setHideButton(true);
            }
        } else setHeight(paragraph.current.scrollHeight + "px");
    }, [lines, expanded]);

    return (
        <div>
            <p className="expandable-paragraph-text" style={{ height }} ref={paragraph}>
                {props.children}
            </p>
            <button
                className={`expandable-paragraph-button ${hideButton ? "expandable-paragraph-button-hidden" : ""}`}
                onClick={() => {
                    setExpanded((e) => !e);
                }}
            >
                {expanded ? collapseText : expandText}
            </button>
        </div>
    );
}