import React from 'react';
import { compiler } from "markdown-to-jsx";
import styles from './Roster.module.css'
import {ExpandableParagraph, Button} from "../../index";
import {FiInstagram} from "react-icons/fi";
import {BsSpotify, BsTwitter, BsYoutube} from "react-icons/bs";
import {CgFacebook} from "react-icons/cg";
import {FaTiktok} from "react-icons/fa";

const ArtsistMarkdown = (props) => {
    const {page, artist} = props
    const importImg = require('../../../data/img/artists/' + artist.img[0]);
    const ArtistCompiler = compiler(page, {  overrides:{
            Expandable: {component: ExpandableParagraph}
        }})

    const socialIcons = (name, link) => {
        switch (name){
            case "instagram":
                return <FiInstagram/>
            case "twitter":
                return <BsTwitter/>
            case "facebook":
                return <CgFacebook/>
            case "youtube":
                return <BsYoutube/>
            case "spotify":
                return <BsSpotify/>
            case "tiktok":
                return <FaTiktok/>
            default:
                return null
        }
    }

    return (
        <>
            <div className={styles.subsection}>
                <div className={styles.bio}>
                    <img src={importImg} className={styles.bioImage}/>
                    <div className={styles.bioPage}>
                        {ArtistCompiler}
                    </div>
                    <div className={styles.socials}>
                        {artist.social_name.map((x, i) => {
                            return <div className={styles.socialIcon}><a href={artist.social_link[i]} title={x} target={"_blank"}>{socialIcons(x)}</a></div>
                        })}
                    </div>
                </div>
            </div>

        </>
    );
};

export default ArtsistMarkdown;