import { useCallback, useState } from "react";
import {ApiSendInsertCoin} from "../api";

const useSendInsertCoin = () => {
    const [sending, setSending] = useState(false);
    const [result, setResult] = useState(null);

    const insertCoin = useCallback(async (data) => {
        setSending(true);
        const { res } = await ApiSendInsertCoin(data);
        setResult(res);
    }, []);

    return [insertCoin, sending, result];
};

export default useSendInsertCoin;