import React from 'react';
import styles from "./Roster.module.css";

import Oro from '../../../data/img/oro.png'
import DoppioOro from '../../../data/img/oro-doppio.png'
import TriploOro from '../../../data/img/oro-triplo.png'
import Platino from '../../../data/img/platino.png'
import DoppioPlatino from '../../../data/img/platino-doppio.png'
import TriploPlatino from '../../../data/img/platino-triplo.png'
import QuadruploPlatino from '../../../data/img/platino-quadruplo.png'

const Awards = (props) => {
    const {artist} = props;

    const award_icon = (icon) => {
        switch (icon) {
            case 'one-gold':
                return <img src={Oro} alt={"oro"} className={styles.awardIcon} title="Disco d'oro" />
            case 'two-gold':
                return <img src={DoppioOro} alt={"doppio-oro"} className={styles.awardIcon} title="Disco d'oro"/>
            case 'three-gold':
                return <img src={TriploOro} alt={"triplo-oro"} className={styles.awardIcon} title="Disco d'oro" />
            case 'one-platinum':
                return <img src={Platino} alt={"platino"} className={styles.awardIcon} title="Disco di platino" />
            case 'two-platinum':
                return <img src={DoppioPlatino} alt={"doppio-platino"} className={styles.awardIcon} title="Doppio disco di platino"/>
            case 'three-platinum':
                return <img src={TriploPlatino} alt={"triplo-platino"} className={styles.awardIcon} title="Triplo disco di platino"/>
            case 'four-platinum':
                return <img src={QuadruploPlatino} alt={"quadruplo-platino"} className={styles.awardIcon} title="Triplo disco di platino"/>
            default:
                return null
        }
    }

    return (
        <>
            <div className={styles.subsection}>
            <h2 className={styles.title}>
                AWARDS
            </h2>
            {artist.awards_songs.map((x, i) => {
                return (
                    <h3 key={i} className={styles.awards}>

                        {award_icon(artist.awards_type[i])}
                        {x}
                    </h3>
                )
            })}
            </div>
        </>
    );
};

export default Awards;