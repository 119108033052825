import React from 'react';
import {MdMicExternalOn} from "react-icons/md";
import {IoMic, IoMusicalNotesSharp, IoVideocam} from "react-icons/io5";
import {IoMdBrush} from "react-icons/io";
import {BsQuestionLg} from "react-icons/bs";
import {AppContext, useAppContext} from "../../context/AppContext";

const areaIcon = (data, areaList) => {
    let area = []
    areaList.map((x, i) => {
        if (data[x.value]) {
            switch ( x.value) {
                case "rapper":
                    area.push( <MdMicExternalOn size={20}/> )
                    break;
                case "producer":
                    area.push( <IoMusicalNotesSharp size={20}/> )
                    break;
                case "singer":
                    area.push( <IoMic size={20}/> )
                    break;
                case "graphic_designer":
                    area.push( <IoMdBrush size={20}/> )
                    break;
                case "videomaker":
                    area.push( <IoVideocam size={20}/>)
                    break;
                default:
                    return <BsQuestionLg size={20}/>
            }
        }
    })
    return area;
}

const AreaIcon = (props) => {
    const {data} = props;
    const appData = useAppContext(AppContext);


    return (
        <>
            {areaIcon(data, appData.areaList)}
        </>
    );
};

export default AreaIcon;