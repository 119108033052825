import {useEffect, useState} from 'react';
import {ApiGetServiceRequests} from "../api";

const useGetServiceRequests = (page) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        if(state == null){
            setServiceRequests(page);
        }
    }, [page]);

    const setServiceRequests = async (page) => {
        const requestsList = await ApiGetServiceRequests(page);
        setState(requestsList);
    };
    return state;
};

export default useGetServiceRequests;
