import React from 'react';
import {GetUsers} from "../../components";
import withAuth from "../../components/hoc/withAuth";

const ManageUsers = () => {
    return (
        <>
            <GetUsers/>
        </>
    );
};

export default withAuth(ManageUsers);