import React, {useEffect, useContext} from 'react';
import validate from './ValidateInfo';
import styles from '../Form.module.css'
import {Button, InputLeft} from "../../index";

import useFormLogin from "./UseFormLogin";

import {RiLockPasswordLine} from "react-icons/ri";
import {BiAt} from "react-icons/bi";
import {useLogin} from "../../../hooks";
import { useNavigate } from "react-router-dom";

import {AppContext} from "../../../context/AppContext";
import {BallTriangle} from "react-loader-spinner";

const FormLogin = (props) => {
    const appData = useContext(AppContext);

    let navigate = useNavigate();
    const routeChange = () =>{
        let path = `/admin`;
        navigate(path);
    }

    const {handleChange, submit, values, errors, loading} = useFormLogin(
        appData.user.login,
        validate,
    );

    useEffect(() => {
        if(appData.user.data !== null){
            routeChange()
        }
    })



    return (
        <>
            <div className={styles.text}>
                Compila i campi per loggarti. Se non hai un account, chiedi a eiemgei di creartene uno, altrimenti attaccati &lt;3
            </div>
            <form onSubmit={submit} className={styles.form} noValidate>
                <div className={styles.container}>


                    {/*EMAIL*/}

                    <div className={styles.inputs}>
                        {errors.email ?
                            <InputLeft input={"input"} icon={<BiAt size={20} color={"red"}/>} type={"text"}
                                       name={"email"}
                                       placeholder={errors.email} value={values.email} onChange={handleChange}
                                       error={true}/> :
                            <InputLeft input={"input"} icon={<BiAt size={20}/>} type={"text"} name={"email"}
                                       placeholder={"Enter your email"} value={values.email}
                                       onChange={handleChange}/>}
                    </div>

                    {/*PASSWORD*/}
                    <div className={styles.inputs}>
                    {errors.password ?
                        <InputLeft input={"input"} icon={<RiLockPasswordLine size={20} color={"red"}/>} type={"password"}
                                   name={"password"}
                                   placeholder={errors.password} value={values.password} onChange={handleChange}
                                   error={true}/> :
                        <InputLeft input={"input"} icon={<RiLockPasswordLine size={20}/>} type={"password"} name={"password"}
                                   placeholder={"Enter your password"} value={values.password} onChange={handleChange}/>}
                    </div>



                    {!loading ? <Button type='submit'>
                        Login
                    </Button> : <BallTriangle
                        height={50}
                        width={50}
                        radius={5}
                        color="#4fa94d"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                    }
                </div>

            </form>
        </>
    );
};

export default FormLogin;