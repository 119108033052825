import {useState, useEffect} from 'react';
import {ApiGetFilteredInsertCoin, ApiGetInsertCoin} from "../api";


const useDemoFiltered = (email, data, rapper, producer, singer, graphic, videomaker, seen, page) => {
    const [state, setState] = useState([]);

    useEffect(() => {
        setFilters();
        if (filters.length > 0) {
            ApiGetInsertCoin(email, page, filters).then((data => setState(data)));
        } else {
            setState(null)
        }

    }, [rapper, producer, singer, graphic, videomaker, data, seen, page])


    let filters = [];
    const setFilters = () => {
        if (rapper) {
            filters.push("rapper")
        }
        if (singer) {
            filters.push("singer")
        }
        if (producer) {
            filters.push("producer")
        }
        if (graphic) {
            filters.push("graphic_designer")
        }
        if (videomaker) {
            filters.push("videomaker")
        }
        if(seen){
            filters.push("seen")
        }
    }

    const setFilterData = async (filters) => {
        const filteredData = await ApiGetInsertCoin(email, page, filters);
        console.log(filteredData)
        return filteredData.data

        /*let temp = []
        for (let i = 0; i < data.length; i++) {
            let item = data[i]
            let count = 0;
            for (let j = 0; j < filters.length; j++) {
                let filter = filters[j]

                if (item[filter]) {
                    count++;
                }
            }
            if (count === filters.length) {
                temp.push(data[i])
            }
        }
        return temp*/
    }

    return state;
};

export default useDemoFiltered;